import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

export default function Search() {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query") || "";
    setSearchQuery(query);

    if (query) {
      fetch(`
        ${
          process.env.REACT_APP_PUBLIC_URL_STRAPI
        }/products?filters[name][$contains]=${encodeURIComponent(
        query
      )}&populate=*`)
        .then((response) => response.json())
        .then((data) => setResults(data.data));
    }
  }, [location.search]);

  const onHandleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      setResults([]);
    } else {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="container-fl p-5">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
              Trang chủ
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Kết quả tìm kiếm với từ khóa "{searchQuery}"
          </li>
        </ol>
      </nav>

      <div className="mt-2 col-12">
        <h3>Nhập từ khóa tìm kiếm</h3>
      </div>

      <div className="col-12 mt-3">
        <form onSubmit={onHandleSearch}>
          <div className="search-container">
            <input
              type="text"
              className="form-control"
              placeholder="Tìm kiếm ..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="submit" className="search-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </form>
      </div>

      {results.length > 0 ? (
        <div className="results">
          <div className="product-list">
            {results.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      ) : (
        <h3 className="mt-3">
          Không tìm thấy bất kỳ kết quả nào với từ khóa trên.
        </h3>
      )}
    </div>
  );
}

const ProductCard = React.memo(({ product }) => {
  const prices = product?.attributes.sub_cate.map(
    (subCategory) => subCategory.price
  );
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  return (
    <div className="col-lg-3 col-sm-6 col-6 container-card-p mt-4">
      <div
        className="product-card-p"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div>
          <Link to={`/product-detail/${product.id}`}>
            <img
              src={
                process.env.REACT_APP_URL +
                product?.attributes?.currentImage?.data.attributes.url
              }
              alt="..."
              width="100%"
              height="250px"
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            flex: "1",
            paddingTop: "25px",
            paddingBottom: "15px",
            minHeight: "60px",
            textAlign: "center",
          }}
        >
          <Link to={`/product-detail/${product.id}`} className="title-card-p">
            {product?.attributes.name}
          </Link>
        </div>
        <div>
          <p className="price-p">
            {minPrice === maxPrice ? (
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(minPrice)
            ) : (
              <>
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(minPrice)}{" "}
                -{" "}
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(maxPrice)}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
});
