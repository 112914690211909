import { Outlet, useLocation } from "react-router-dom";
import Headers from "../Headers";
import HeaderMobile from "../Headers/Mobile";
import "./Master.css";
import Footer from "../Footer";
import { useEffect } from "react";
import SocialButtons from "../Utils/socialButton";

const Master = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <header>
        <Headers />
        <HeaderMobile />
      </header>
      <Outlet />
      <footer>
        <Footer />
      </footer>
      <SocialButtons />

      {/* <Plugin /> */}
    </>
  );
};
export default Master;
