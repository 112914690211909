import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../../services/common/api/api-public";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [initialCode, setInitialCode] = useState("");

  useEffect(() => {
    document.title = `Đặt lại mật khẩu - Ngọc Oanh Foods`;
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      setInitialCode(code);
    }
  }, [location.search]);

  const onResetPassword = async (values) => {
    setIsButtonDisabled(true);
    const { code, password, passwordConfirmation } = values;

    if (password !== passwordConfirmation) {
      Swal.fire({
        title: "Lỗi",
        text: "Mật khẩu và xác nhận mật khẩu không khớp!",
        icon: "error",
        confirmButtonText: "Đồng ý",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      setIsButtonDisabled(false);
      return;
    }

    try {
      await resetPassword(code, password, passwordConfirmation);
      Swal.fire({
        title: "Thành công",
        text: "Đã đặt lại mật khẩu thành công",
        icon: "success",
        confirmButtonText: "Đồng ý",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
      navigate("/login");
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Có lỗi xảy ra. Vui lòng thử lại.";
      Swal.fire({
        title: "Lỗi",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Đồng ý",
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="container-fl">
      <div className="col-12 mt-3">
        <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
          Trang chủ
        </Link>{" "}
        \ <span style={{ color: "#3e890f" }}>Đặt lại mật khẩu</span>
      </div>

      <p
        className="mt-3"
        style={{ color: "#333", fontSize: "30px", fontWeight: "400" }}
      >
        Đặt lại mật khẩu
      </p>
      <p style={{ marginBottom: "7px" }}>
        Nếu bạn chưa nhận được mã xác thực, vui lòng kiểm tra lại email của bạn
      </p>

      <Formik
        initialValues={{
          code: initialCode,
          password: "",
          passwordConfirmation: "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          code: Yup.string().required("Vui lòng không bỏ trống thông tin"),
          password: Yup.string()
            .min(6, "Mật khẩu ít nhất 6 kí tự")
            .required("Vui lòng không bỏ trống thông tin"),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "Mật khẩu xác nhận không khớp")
            .required("Vui lòng không bỏ trống thông tin"),
        })}
        onSubmit={onResetPassword}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Mã xác thực
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="code"
                    className="email-in"
                    placeholder="Nhập code"
                  />
                  <ErrorMessage
                    name="code"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Mật khẩu mới
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="password"
                    className="email-in"
                    type="password"
                    placeholder="Nhập mật khẩu mới"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Nhập lại mật khẩu mới
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="passwordConfirmation"
                    className="email-in"
                    type="password"
                    placeholder="Nhập lại mật khẩu mới"
                    disabled={!values.password}
                  />
                  <ErrorMessage
                    name="passwordConfirmation"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <div className="col-6 mt-4">
                <button
                  className="btn-login-l"
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  Đặt lại mật khẩu
                </button>
                <Link
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#333",
                    marginLeft: "20px",
                  }}
                >
                  Đăng nhập
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
