import { useEffect, useState } from "react";
import { getIntroduce } from "../../../services/common/api/api-public";
import "./introduce.css";
import { Link } from "react-router-dom";
export default function Introduce() {
  const [introduce, setIntroduce] = useState();
  useEffect(() => {
    document.title = "Giới thiệu - Ngọc Oanh Foods";
  });

  useEffect(() => {
    getIntroduce().then((res) => {
      setIntroduce(res.data);
    });
  }, []);

  if (!introduce) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl">
        <div className="col-12 mt-3">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \{" "}
          <span style={{ color: "#3e890f" }}>
            {introduce?.attributes.title}
          </span>
        </div>
        <div className="col-12 mt-3">
          <h4>{introduce?.attributes.title}</h4>
        </div>
        <div className="col-12 mt-3">
          <div
            dangerouslySetInnerHTML={{
              __html:
                introduce?.attributes?.content || "Chưa có dữ liệu chi tiết",
            }}
            style={{ maxWidth: "100%", overflow: "hidden" }}
          ></div>
        </div>
      </div>
    </>
  );
}
