import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, updateUserInfo } from "../../../redux/slide/user-slice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateInformationUser } from "../../../services/common/api/api-private";
import { getAccessToken } from "../../Utils/tokenManagement";

export default function UpdateInformation() {
  const userInfo = useSelector(getUserInfo);
  const [city, setCity] = useState(userInfo.city || "");
  const [code, setCode] = useState(userInfo.code || "");
  const [phone, setPhone] = useState(userInfo.phone || "");
  const [username, setUsername] = useState(userInfo.username || "");
  const [defaultAddress, setDefaultAddress] = useState(
    userInfo.default_address || ""
  );
  const [corp, setCorp] = useState(userInfo.corp || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = getAccessToken();

  useEffect(() => {
    document.title = `Cập nhật thông tin - Ngọc Oanh Foods`;
  }, []);

  useEffect(() => {}, [jwt, userInfo]);
  const onHandleUpdateInformation = () => {
    const data = {
      username: username || userInfo.username,
      phone: phone,
      default_address: defaultAddress,
      city: city,
      zip_code: code || "",
      corp: corp,
    };

    updateInformationUser(userInfo.id, data, jwt).then((res) => {
      toast.success("Cập nhật thành công");
      dispatch(updateUserInfo(res));
      navigate(`/account/${userInfo.id}`);
    });
  };

  return (
    <>
      <div className="container-fl mt-5 mb-4">
        <div>
          <h6>
            <b>CẬP NHẬT THÔNG TIN</b>
          </h6>
        </div>
        <div className="mt-3">
          <div id="example-collapse-text">
            <div className="col-12">
              <label className="form-label">
                Họ và tên<b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <label className="form-label">Công ty</label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={corp}
                  onChange={(e) => setCorp(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <label className="form-label">
                Địa chỉ mặc định<b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={defaultAddress}
                  onChange={(e) => setDefaultAddress(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <label className="form-label">
                Thành phố<b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <label className="form-label">Mã Zip</label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <label className="form-label">
                Số điện thoại<b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-12">
                <input
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <button
                className="btn btn-success"
                onClick={onHandleUpdateInformation}
              >
                Cập nhật
              </button>
              <button
                className="btn btn-outline-primary ms-3"
                onClick={() => navigate(`/account/${userInfo.id}`)}
              >
                Quay lại
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
