import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import error from "../../assets/homePage/404.png";
import "./404.css";

export default function PageError() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const onHandleSearch = () => {
    if (searchQuery.trim() === "") {
      alert("Vui lòng nhập từ khóa tìm kiếm.");
      return;
    }
    navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <>
      <div className="container-fl mt-3">
        <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
          Trang chủ
        </Link>{" "}
        \{" "}
        <span style={{ color: "#3e890f" }}>
          404 không tìm thấy trang
        </span>
        <div className="col-12 row p-5">
          <div className="col-4">
            <img src={error} alt="404.png" />
          </div>
          <div className="col-8">
            <div className="col-12">
              <h4>
                Xin lỗi, chúng tôi không tìm thấy kết quả nào phù hợp. Vui lòng
                gõ lại từ khóa vào ô tìm kiếm
              </h4>
            </div>
            <div className="col-12">Bạn nên:</div>
            <div className="col-12 mt-2">
              1) Kiểm tra lại từ khóa có thể bạn đã gõ sai.
            </div>
            <div className="col-12 mt-2">
              2) Hãy dùng từ khóa ngắn hơn và đơn giản hơn.
            </div>
            <div className="col-12 mt-2">
              3) Gõ lại từ khóa vào ô tìm kiếm dưới đây:
            </div>
            <div className="col-12 mt-2">
              <div className="search-container">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tìm kiếm ..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="search-button" onClick={onHandleSearch}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="col-12 mt-2">
              <strong>
                Liên hệ tổng đài Chăm sóc khách hàng{" "}
                <span style={{ color: "#FCAF14" }}>0961.239.667</span> để được
                hỗ trợ
              </strong>
            </div>
            <div className="col-12 mt-2">
              Chỉ cần cung cấp tên sản phẩm bạn muốn tìm. Chúng tôi sẽ hỗ trợ
              tìm kiếm sản phẩm cho bạn
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
