import "./Detail.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slide/user-slice";
import { cartActions } from "../../redux/slide/cart-slice";
import * as Yup from "yup";
import {
  createFormContact,
  findProductById,
  getAllProductByIdCate,
  getCategories,
} from "../../services/common/api/api-public";
import {
  addToCart,
  checkIdSubCate,
  checkProductOnCart,
  getCartItems,
  getInformationUser,
  updateProductOnCart,
} from "../../services/common/api/api-private";
import { Field, Form, Formik } from "formik";
import { getAccessToken } from "../Utils/tokenManagement";
export default function Detail() {
  const [certificationCurrent, setCertificationCurrent] = useState();
  const [flagCerStatus, setFlagCerStatus] = useState(false);
  const [openCer, setOpenCer] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [product, setProduct] = useState();
  const [categories, setCategories] = useState();
  const [currentImg, setCurrentImg] = useState();
  const userInfo = useSelector(getUserInfo);
  // eslint-disable-next-line
  const [cardId, setCartId] = useState([]);
  const [count, setCount] = useState(1);
  const [images, setImages] = useState();
  const [subCate, setSubCate] = useState();
  const jwt = getAccessToken();
  const dispatch = useDispatch();
  const [currentPrice, setCurrentPrice] = useState();
  const [selectSubCate, setSelectedSubCate] = useState();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [products, setProducts] = useState();
  const [activeTab, setActiveTab] = useState("description");
  let timerInterval;

  useEffect(() => {
    const { id } = params;
    if (params && id) {
      findProductById(id).then((res) => {
        setProduct(res.data);
        setCurrentImg(res.data.attributes.currentImage.data.attributes.url);
        const subCategories = res.data.attributes.sub_cate;
        setSubCate(subCategories);
        setCurrentPrice(res.data.attributes.sub_cate[0].price);
        setSelectedSubCate(res.data.attributes.sub_cate[0]);
        if (res.data.attributes.images.data != null) {
          setImages(res.data.attributes.images.data);
        }
        const idCate = res.data.attributes.category.data.id;
        getAllProductByIdCate(idCate).then((resp) => {
          setProducts(resp.data);
        });
      });
    }

    getCategories().then((res) => {
      setCategories(res.data);
    });

    if (jwt) {
      getInformationUser(jwt).then((resp) => {
        setCartId(resp);
      });
    }
  }, [params, jwt, setCartId]);

  const openModalCer = (url) => {
    setCertificationCurrent(url);
    setFlagCerStatus(!flagCerStatus);
  };

  const closeModalCer = () => {
    setOpenCer(false);
  };

  useEffect(() => {
    if (certificationCurrent) {
      setOpenCer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagCerStatus]);

  const handleChangeSizeCart = () => {
    getCartItems(userInfo.id, jwt).then((res) => {
      const size = res.data.reduce((c, cart) => {
        return c + cart.attributes.quantity;
      }, 0);
      dispatch(cartActions.setCartSize(size));
    });
  };

  const handleSelectChange = (e) => {
    const selectedSub = JSON.parse(e.target.value);
    setCurrentPrice(selectedSub.price);
    setSelectedSubCate(selectedSub);
  };

  const currentQuantityProduct = selectSubCate?.quantity;

  const onAddToCart = () => {
    if (!userInfo.username) {
      Swal.fire({
        title: "Vui lòng đăng nhập để mua hàng",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3e890f",
        cancelButtonColor: "#3e890f",
        border: "none",
        confirmButtonText: "Đóng",
      });
      return;
    }

    try {
      const amount = parseFloat(count * selectSubCate.price);
      const payload = {
        data: {
          products: product?.id,
          quantity: count,
          amount: amount,
          users_permissions_user: userInfo.username,
          userId: userInfo.id,
          id_sub_category: selectSubCate.id,
        },
      };
      checkProductOnCart(userInfo.id, product.id, jwt).then((res) => {
        if (res.data.length > 0) {
          checkIdSubCate(userInfo.id, product.id, selectSubCate.id, jwt).then(
            (res) => {
              if (res.data.length >= 1) {
                updateProductOnCart(
                  res.data[0].id,
                  res.data[0].attributes.quantity + count,
                  parseFloat(res.data[0].attributes.amount + amount),
                  jwt
                ).then(() => {
                  handleChangeSizeCart();
                  Swal.fire({
                    text: "Sản phẩm đã được cập nhật vào giỏ hàng",
                    icon: "success",
                    confirmButtonText: "Đồng ý (5)",
                    customClass: {
                      confirmButton: "custom-swal-button",
                    },
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: () => {
                      const swalConfirmButton = Swal.getConfirmButton();
                      let timeLeft = 5;

                      timerInterval = setInterval(() => {
                        timeLeft -= 0.1;
                        swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                          timeLeft
                        )})`;
                      }, 100);
                    },
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  });
                });
              } else {
                addToCart(payload, jwt)
                  .then(() => {
                    handleChangeSizeCart();
                    Swal.fire({
                      text: "Sản phẩm đã được thêm vào giỏ hàng",
                      icon: "success",
                      confirmButtonText: "Đồng ý (5)",
                      customClass: {
                        confirmButton: "custom-swal-button",
                      },
                      timer: 5000,
                      timerProgressBar: true,
                      didOpen: () => {
                        const swalConfirmButton = Swal.getConfirmButton();
                        let timeLeft = 5;

                        timerInterval = setInterval(() => {
                          timeLeft -= 0.1;
                          swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                            timeLeft
                          )})`;
                        }, 100);
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      },
                    });
                  })
                  .catch((error) => {
                    console.error("Error adding to cart:", error);
                    Swal.fire({
                      text: "Đã xảy ra lỗi khi thêm sản phẩm vào giỏ hàng",
                      icon: "error",
                      confirmButtonText: "Đồng ý (5)",
                      customClass: {
                        confirmButton: "custom-swal-button",
                      },
                      timer: 5000,
                      timerProgressBar: true,
                      didOpen: () => {
                        const swalConfirmButton = Swal.getConfirmButton();
                        let timeLeft = 5;

                        timerInterval = setInterval(() => {
                          timeLeft -= 0.1;
                          swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                            timeLeft
                          )})`;
                        }, 100);
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      },
                    });
                  });
              }
            }
          );
        } else {
          addToCart(payload, jwt)
            .then(() => {
              handleChangeSizeCart();
              Swal.fire({
                text: "Sản phẩm đã được thêm vào giỏ hàng",
                icon: "success",
                confirmButtonText: "Đồng ý (5)",
                customClass: {
                  confirmButton: "custom-swal-button",
                },
                timer: 5000,
                timerProgressBar: true,
                didOpen: () => {
                  const swalConfirmButton = Swal.getConfirmButton();
                  let timeLeft = 5;

                  timerInterval = setInterval(() => {
                    timeLeft -= 0.1;
                    swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                      timeLeft
                    )})`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
            })
            .catch((error) => {
              console.error("Error adding to cart:", error);
              Swal.fire({
                text: "Đã xảy ra lỗi khi thêm sản phẩm vào giỏ hàng",
                icon: "error",
                confirmButtonText: "Đồng ý (5)",
                customClass: {
                  confirmButton: "custom-swal-button",
                },
                timer: 5000,
                timerProgressBar: true,
                didOpen: () => {
                  const swalConfirmButton = Swal.getConfirmButton();
                  let timeLeft = 5;

                  timerInterval = setInterval(() => {
                    timeLeft -= 0.1;
                    swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                      timeLeft
                    )})`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
            });
        }
      });
    } catch (error) {
      console.error("Unexpected error:", error);
      Swal.fire({
        text: "Đã xảy ra lỗi không mong muốn",
        icon: "error",
        confirmButtonText: "Đồng ý (5)",
        customClass: {
          confirmButton: "custom-swal-button",
        },
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          const swalConfirmButton = Swal.getConfirmButton();
          let timeLeft = 5;

          timerInterval = setInterval(() => {
            timeLeft -= 0.1;
            swalConfirmButton.textContent = `Đồng ý (${Math.ceil(timeLeft)})`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    }
  };

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  if (!product) return <div className="loader"></div>;

  return (
    <>
      <div className="container-fl de-container">
        <div style={{ padding: "20px 0px" }}>
          <span>Sản phẩm \ </span>
          <span style={{ color: "#3e890f" }}>
            {product?.attributes.name}
          </span>
        </div>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="col-lg-9 col-12 d-flex" style={{ flexWrap: "wrap" }}>
            <div className="col-md-5 col-12 pe-4">
              <div className="img-de-container">
                <img
                  src={process.env.REACT_APP_URL + currentImg}
                  alt="..."
                  width="100%"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openModalCer(process.env.REACT_APP_URL + currentImg);
                  }}
                />
              </div>
              {images && (
                <div>
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={25}
                    speed={1100}
                    navigation={true}
                    modules={[Navigation, Autoplay]}
                  >
                    {images.map((item) => (
                      <SwiperSlide key={item.id}>
                        <div
                          onClick={() => {
                            setCurrentImg(item.attributes.url);
                          }}
                        >
                          <div
                            className="d-flex justify-content-center"
                            style={{
                              border: "1px solid #ebebeb",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                process.env.REACT_APP_URL + item.attributes.url
                              }
                              alt="..."
                              className="img-de"
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
            <div className="col-md-7 col-12">
              <p className="title-de">{product?.attributes?.name}</p>
              <p
                style={{
                  cursor: "pointer",
                  marginBottom: "15px",
                  fontSize: "15px",
                }}
              >
                Viết đánh giá
              </p>
              <div className="d-flex dbp" style={{ marginBottom: "30px" }}>
                <p style={{ alignContent: "center" }}>Trạng thái: </p>

                {currentQuantityProduct > 0 ? (
                  <p className="box-ava mx-2">Còn hàng</p>
                ) : (
                  <p className="box-ava mx-2">Hết hàng</p>
                )}
              </div>
              <p className="price-de">
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(currentPrice)}
              </p>
              <div
                style={{
                  borderBottom: "1px solid #ebebeb",
                  marginBottom: "15px",
                }}
              >
                <p className="s-description-de">
                  {product?.attributes?.short_description || "Chưa có mô tả"}
                </p>
              </div>
              <div className="col-12 row">
                <p className="mb-3">Loại sản phẩm</p>
                <div>
                  <select className="weight-de" onChange={handleSelectChange}>
                    {subCate.map((sub) => (
                      <option
                        value={JSON.stringify(sub)}
                        key={sub.id}
                        disabled={sub.quantity === 0}
                      >
                        {sub.name} {sub.quantity === 0 ? "(hết hàng)" : ""}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3 mt-4">
                  <p className="mb-2">Số lượng</p>
                </div>
                <div
                  className="col-4 mt-4 d-flex quantity-de-btn"
                  style={{ maxHeight: "36px" }}
                >
                  <button className="btn btn-success me-3" onClick={decrement}>
                    -
                  </button>
                  <span>{count}</span>
                  <button
                    className="btn btn-success ms-3"
                    onClick={() => {
                      if (count < currentQuantityProduct) {
                        increment();
                      }
                    }}
                  >
                    +
                  </button>
                </div>
                <div className="col-5">
                  <span className="btn-atc" onClick={() => onAddToCart()}>
                    Thêm vào giỏ hàng
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 mt-3">
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  className="btn"
                  onClick={() => setActiveTab("description")}
                  style={{
                    backgroundColor:
                      activeTab === "description" ? "#ff8c00" : "#3e890f",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Mô tả
                </button>
                <button
                  className="btn"
                  onClick={() => setActiveTab("information")}
                  style={{
                    backgroundColor:
                      activeTab === "information" ? "#ff8c00" : "#3e890f",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Thông tin
                </button>
              </div>

              {activeTab === "description" && (
                <>
                  <div
                    className="mt-3 description-de-body col-12"
                    style={{
                      maxWidth: "100%",
                      color: "#323c3f",
                      marginBottom: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        product?.attributes?.long_description ||
                        "Chưa có mô tả chi tiết",
                    }}
                  ></div>

                  <div className="col-12">
                    <Formik
                      initialValues={{
                        name: "",
                        phone: "",
                        email: "",
                        message: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required(
                          "Vui lòng nhập đầy đủ thông tin"
                        ),
                        phone: Yup.string().required(
                          "Vui lòng nhập đầy đủ thông tin"
                        ),
                        email: Yup.string()
                          .email("Vui lòng nhập đúng định dạng email")
                          .required("Vui lòng nhập đầy đủ thông tin"),
                        message: Yup.string().required(
                          "Vui lòng nhập nội dung yêu cầu"
                        ),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        createFormContact(values);
                        Swal.fire({
                          title: "Gửi liên hệ thành công",
                          icon: "success",
                          confirmButtonText: "Đồng ý",
                          customClass: {
                            confirmButton: "custom-swal-button",
                          },
                        });
                        setSubmitting(false);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Tên khách hàng"
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}

                          <Field
                            type="text"
                            name="phone"
                            className="form-control mt-3"
                            placeholder="SĐT liên hệ"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="text-danger">{errors.phone}</div>
                          ) : null}

                          <Field
                            name="email"
                            type="text"
                            className="form-control mt-3"
                            placeholder="Email"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}

                          <Field
                            name="message"
                            as="textarea"
                            className="form-control mt-3"
                            placeholder="Nội dung/yêu cầu"
                          />
                          {errors.message && touched.message ? (
                            <div className="text-danger">{errors.message}</div>
                          ) : null}

                          <div className="mt-4">
                            <button className="btn btn-success" type="submit">
                              Gửi liên hệ
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              )}

              {activeTab === "information" && (
                <>
                  <div
                    className="mt-3 description-de-body col-12"
                    style={{
                      maxWidth: "100%",
                      color: "#323c3f",
                      marginBottom: "20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        product?.attributes?.information ||
                        "Các nội dung Hướng dẫn mua hàng viết ở đây",
                    }}
                  ></div>
                  <div className="col-12">
                    <Formik
                      initialValues={{
                        name: "",
                        phone: "",
                        email: "",
                        message: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required(
                          "Vui lòng nhập đầy đủ thông tin"
                        ),
                        phone: Yup.string().required(
                          "Vui lòng nhập đầy đủ thông tin"
                        ),
                        email: Yup.string()
                          .email("Vui lòng nhập đúng định dạng email")
                          .required("Vui lòng nhập đầy đủ thông tin"),
                        message: Yup.string().required(
                          "Vui lòng nhập nội dung yêu cầu"
                        ),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        createFormContact(values);
                        Swal.fire({
                          title: "Gửi liên hệ thành công",
                          icon: "success",
                          confirmButtonText: "Đồng ý",
                          customClass: {
                            confirmButton: "custom-swal-button",
                          },
                        });
                        setSubmitting(false); // Optional: To reset the submitting state
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Tên khách hàng"
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}

                          <Field
                            type="text"
                            name="phone"
                            className="form-control mt-3"
                            placeholder="SĐT liên hệ"
                          />
                          {errors.phone && touched.phone ? (
                            <div className="text-danger">{errors.phone}</div>
                          ) : null}

                          <Field
                            name="email"
                            type="text"
                            className="form-control mt-3"
                            placeholder="Email"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}

                          <Field
                            name="message"
                            as="textarea"
                            className="form-control mt-3"
                            placeholder="Nội dung/yêu cầu"
                          />
                          {errors.message && touched.message ? (
                            <div className="text-danger">{errors.message}</div>
                          ) : null}

                          <div className="mt-4">
                            <button className="btn btn-success" type="submit">
                              Gửi liên hệ
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-3 rps-sb-de">
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>DANH MỤC</p>
              </div>
              <div>
                <div className="filter-content" style={{ margin: "0px 30px" }}>
                  <p
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    TẤT CẢ SẢN PHẨM
                  </p>
                  {categories?.map((cate) => (
                    <p
                      key={cate.id}
                      onClick={() => {
                        navigate(`/products-category/${cate.attributes.name}`);
                      }}
                    >
                      {cate.attributes.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>SẢN PHẨM NỔI BẬT</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-9 mt-3">
          <div className="col-12" style={{ textAlign: "center" }}>
            <h1 style={{ color: "#3e890f" }}>CÁC SẢN PHẨM LIÊN QUAN</h1>
            <h6>Có phải bạn đang tìm những sản phẩm dưới đây</h6>
          </div>
          <div className="product-list">
            {products?.map((p) => (
              <ProductCard key={p.id} product={p} />
            ))}
          </div>
          <div></div>
        </div>
      </div>

      {openCer ? (
        <div className="modal-certification">
          <div
            style={{
              position: "fixed",
              top: "0",
              right: "0",
              padding: "10px 10px",
              cursor: "pointer",
            }}
            onClick={closeModalCer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
          <img src={certificationCurrent} alt="..." className="img-cer" />
        </div>
      ) : (
        <></>
      )}

      <div
        className="filter-popup-icon"
        onClick={() => {
          setIsOpenFilter(true);
        }}
      >
        <span className="material-symbols-outlined">filter_alt</span>
      </div>

      <div className={`filter-popup ${isOpenFilter ? "filter-popup-vis" : ""}`}>
        <div style={{ position: "relative" }}>
          <div style={{ overflowY: "scroll", maxHeight: "100vh" }}>
            <div
              className="close-filter-pop"
              onClick={() => {
                setIsOpenFilter(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>DANH MỤC</p>
              </div>
              <div>
                <div className="filter-content" style={{ margin: "0px 30px" }}>
                  <p
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    TẤT CẢ SẢN PHẨM
                  </p>
                  {categories?.map((cate) => (
                    <p
                      key={cate.id}
                      onClick={() => {
                        navigate(`/products-category/${cate.attributes.name}`);
                      }}
                    >
                      {cate.attributes.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`filter-popup-layer ${
          isOpenFilter ? "filter-popup-layer-vis" : ""
        }`}
        onClick={() => {
          setIsOpenFilter(false);
        }}
      ></div>
    </>
  );
}

const ProductCard = React.memo(({ product }) => {
  const prices = product?.attributes.sub_cate.map(
    (subCategory) => subCategory.price
  );
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  return (
    <div className="col-lg-3 col-sm-6 col-6 container-card-p">
      <div
        className="product-card-p"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div>
          <img
            src={
              process.env.REACT_APP_URL +
              product?.attributes?.currentImage?.data.attributes.url
            }
            alt={product?.attributes?.name}
            width="100%"
            height="250px"
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            flex: "1",
            paddingTop: "25px",
            paddingBottom: "15px",
            minHeight: "60px",
            textAlign: "center",
          }}
        >
          <Link to={`/product-detail/${product.id}`} className="title-card-p ">
            <span> {product?.attributes?.name}</span>
          </Link>
        </div>
        <div>
          <p className="price-p">
            {minPrice === maxPrice ? (
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(minPrice)
            ) : (
              <>
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(minPrice)}{" "}
                -{" "}
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(maxPrice)}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
});
