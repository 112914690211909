import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { formatDate } from "../Utils/formatDate";
import {
  getAllBlog,
  getCategories,
} from "../../services/common/api/api-public";
export default function Blog() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [blogs, setBlogs] = useState();

  useEffect(() => {
    document.title = "Tin tức - Ngọc Oanh Foods";
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogRes = await getAllBlog();
        setBlogs(blogRes.data);

        const categoriesRes = await getCategories();
        setCategories(categoriesRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (!categories || !blogs) return <div className="loader"></div>;

  return (
    <>
      <div>
        <div className="container-fl">
          <div>
            <p className="breadcrum">
              <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
                Trang chủ
              </Link>{" "}
              \ <span style={{ color: "#3e890f" }}>Tin tức</span>
            </p>
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <div className="col-3 rps-sb-p">
              <div>
                <div className="box-filter">
                  <div
                    style={{ padding: "10px 30px" }}
                    className="title-filter"
                  >
                    <p>DANH MỤC</p>
                  </div>
                  <div>
                    <div
                      className="filter-content"
                      style={{ margin: "0px 30px" }}
                    >
                      <p
                        onClick={() => {
                          navigate("/products");
                        }}
                      >
                        TẤT CẢ SẢN PHẨM
                      </p>
                      {categories.map((cate) => (
                        <p
                          key={cate.id}
                          onClick={() => {
                            navigate(
                              `/products-category/${cate.attributes.name}`
                            );
                          }}
                        >
                          {cate.attributes.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 container-list-p">
              <div className="col-12">
                <h3>Tin tức (Có tất cả {blogs?.length} bài viết)</h3>
              </div>
              <div className="product-list">
                {blogs?.map((p) => (
                  <BlogCart key={p.id} blog={p} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const BlogCart = React.memo(({ blog }) => {
  return (
    <div className="col-lg-12 col-sm-12 col-12 container-card-p">
      <div
        className="col-12 row d-flex align-items-center"
        style={{
          border: "solid 1px #F4F4F4",
          borderRadius: "10px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <div className="col-4">
          <img
            src={
              process.env.REACT_APP_URL +
              blog?.attributes?.thumbnail?.data.attributes.url
            }
            alt={blog?.attributes?.name}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </div>
        <div className="col-8">
          <div
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <Link to={`/blog/${blog.id}`} className="title-card-p ">
              <span>
                <strong>{blog?.attributes?.title}</strong>
              </span>
            </Link>
          </div>
          <div style={{ marginTop: "5px", color: "#777777" }}>
            <p style={{ marginBottom: "10px", fontSize: "14px" }}>
              {formatDate(blog.attributes.createdAt)}
            </p>
          </div>
          <div>
            <span style={{ color: "#555555", fontSize: "14px" }}>
              {blog?.attributes?.excerpt}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});
