import { Helmet } from "react-helmet-async";

const Seo = ({ seo, defaultSeo }) => {
  const seoTitle = seo?.metaTitle || defaultSeo?.metaTitle;
  const seoDescription = seo?.metaDescription || defaultSeo?.metaDescription;
  const seoImage =
    seo?.metaImage || defaultSeo?.metaImage?.data?.attributes?.url;

  return (
    <Helmet>
      {seoTitle && <title>{seoTitle}</title>}
      {seoDescription && <meta name="description" content={seoDescription} />}
      {seoImage && <meta property="og:image" content={seoImage} />}
      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {seoDescription && (
        <meta property="og:description" content={seoDescription} />
      )}
      {seoTitle && <meta name="twitter:title" content={seoTitle} />}
      {seoImage && <meta name="twitter:image" content={seoImage} />}
    </Helmet>
  );
};

export default Seo;
