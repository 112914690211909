import React, { useEffect, useState } from "react";
import { getCorpInfo } from "./../../services/common/api/api-public";
import fb from "../../assets/homePage/social-button/faceicon.png";
import zl from "../../assets/homePage/social-button/zalo-icon.png";
const SocialButtons = () => {
  const [info, setInfo] = useState();
  useEffect(() => {
    getCorpInfo().then((res) => {
      setInfo(res.data);
    });
  }, []);

  if (!info) return <div className="loader"></div>;
  return (
    <div style={styles.container}>
      <a
        href={info?.attributes.link_facebook}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
      >
        <img src={fb} alt="Facebook" style={styles.icon} />
      </a>
      <a
        href={`https://zalo.me/${info?.attributes.phone}`}
        target="_blank"
        rel="noopener noreferrer"
        style={styles.button}
      >
        <img src={zl} alt="Zalo" style={styles.icon} />
      </a>

      <a href={`tel:${info?.attributes.phone}`} style={styles.phoneButton}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          fill="currentColor"
          className="bi bi-telephone-fill"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
          />
        </svg>
        <span style={styles.phoneText}>{info?.attributes.phone}</span>
      </a>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "10px",
    left: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    zIndex: 9999,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  icon: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  },
  phoneButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    borderRadius: "25px",
    backgroundColor: "#e74c3c",
    color: "#fff",
    textDecoration: "none",
    fontWeight: "bold",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  phoneText: {
    marginLeft: "10px",
  },
};

export default SocialButtons;
