import { useEffect, useState } from "react";
import Partner from "../../Partner";
import {
  createFormContact,
  getCorpInfo,
} from "../../../services/common/api/api-public";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import Swal from "sweetalert2";

export default function Contact() {
  const [info, setInfo] = useState();

  useEffect(() => {
    document.title = "Liên hệ - Ngọc Oanh Foods";
  });

  useEffect(() => {
    getCorpInfo().then((res) => {
      setInfo(res.data);
    });
  }, []);

  const toastSuccess = () => {
    let timerInterval;
    Swal.fire({
      text: "Gửi liên hệ thành công",
      icon: "success",
      confirmButtonText: "Đồng ý (5)",
      customClass: {
        confirmButton: "custom-swal-button",
      },
      timer: 5000,
      timerProgressBar: true,
      didOpen: () => {
        const swalConfirmButton = Swal.getConfirmButton();
        let timeLeft = 5;

        timerInterval = setInterval(() => {
          timeLeft -= 0.1;
          swalConfirmButton.textContent = `Đồng ý (${Math.ceil(timeLeft)})`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    });
  };

  if (!info) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl mt-5 ">
        <div className="col-lg-12 row">
          <div className="col-6">
            <div className="col-12">
              <Formik
                initialValues={{
                  name: "",
                  phone: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string().required("Vui lòng nhập đầy đủ thông tin"),
                  phone: Yup.string().required(
                    "Vui lòng nhập đầy đủ thông tin"
                  ),
                  email: Yup.string()
                    .email("Vui lòng nhập đúng định dạng email")
                    .required("Vui lòng nhập đầy đủ thông tin"),
                  message: Yup.string().required(
                    "Vui lòng nhập nội dung yêu cầu"
                  ),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  createFormContact(values);
                  toastSuccess();
                  setSubmitting(false);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Tên khách hàng"
                    />
                    {errors.name && touched.name ? (
                      <div className="text-danger">{errors.name}</div>
                    ) : null}

                    <Field
                      type="text"
                      name="phone"
                      className="form-control mt-3"
                      placeholder="SĐT liên hệ"
                    />
                    {errors.phone && touched.phone ? (
                      <div className="text-danger">{errors.phone}</div>
                    ) : null}

                    <Field
                      name="email"
                      type="text"
                      className="form-control mt-3"
                      placeholder="Email"
                    />
                    {errors.email && touched.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : null}

                    <Field
                      name="message"
                      as="textarea"
                      className="form-control mt-3"
                      placeholder="Nội dung/yêu cầu"
                    />
                    {errors.message && touched.message ? (
                      <div className="text-danger">{errors.message}</div>
                    ) : null}

                    <div className="mt-4">
                      <button className="btn btn-success" style={{backgroundColor: "#3e890f"}} type="submit">
                        Gửi liên hệ
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
                style={{ color: "#3e890f" }}
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
              </svg>{" "}
              <strong>Địa chỉ</strong>
            </div>
            <div className="mt-2 col-12">{info && info.attributes.address}</div>
            <div className="col-12 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-telephone-fill"
                viewBox="0 0 16 16"
                style={{ color: "#3e890f" }}
              >
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>{" "}
              <strong>Số điện thoại</strong>
            </div>
            <div className="col-12 mt-2">
              <div className="col-12" style={{ color: "orange" }}>
                {info && info.attributes.phone}
              </div>
              <div className="col-12 mt-2">
                {info && info.attributes.working_hours}
              </div>
            </div>
            <div className="col-12 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-envelope-fill"
                viewBox="0 0 16 16"
                style={{ color: "#3e890f" }}
              >
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
              </svg>{" "}
              <strong>Email</strong>
            </div>
            <div className="col-12 mt-2" style={{ color: "orange" }}>
              {info && info.attributes.email}
            </div>
          </div>
        </div>
      </div>
      <Partner />
    </>
  );
}
