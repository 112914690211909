// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #3e890f;
  --primary-hover-color: #4da012;
  --text-color: #333333;
  --border-color: #cccccc;
  --background-color: #ffffff;
  --table-background: #f9f9f9;
}

.account-info h3, .account-details h4, .username {
  color: var(--primary-color);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  text-align: center;
  background-color: var(--table-background);
}

table, th, td {
  border: 1px solid var(--border-color);
}

th, td {
  padding: 10px;
  text-align: center;
  color: var(--text-color);
}

.account-details ul {
  list-style-type: none;
  padding: 0;
}

.account-details li {
  margin: 5px 0;
  color: var(--text-color);
}

.button-upd {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-upd:hover {
  background-color: var(--primary-hover-color);
}

.none-bo th, .none-bo td, .none-bo table {
  border: none !important;
}

.none-bo th, .none-bo td {
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/components/User/user.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,qBAAqB;EACrB,uBAAuB;EACvB,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,sCAAsC;EACtC,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[":root {\n  --primary-color: #3e890f;\n  --primary-hover-color: #4da012;\n  --text-color: #333333;\n  --border-color: #cccccc;\n  --background-color: #ffffff;\n  --table-background: #f9f9f9;\n}\n\n.account-info h3, .account-details h4, .username {\n  color: var(--primary-color);\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 10px;\n  text-align: center;\n  background-color: var(--table-background);\n}\n\ntable, th, td {\n  border: 1px solid var(--border-color);\n}\n\nth, td {\n  padding: 10px;\n  text-align: center;\n  color: var(--text-color);\n}\n\n.account-details ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.account-details li {\n  margin: 5px 0;\n  color: var(--text-color);\n}\n\n.button-upd {\n  margin-top: 10px;\n  padding: 10px 20px;\n  background-color: var(--primary-color);\n  color: var(--background-color);\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.button-upd:hover {\n  background-color: var(--primary-hover-color);\n}\n\n.none-bo th, .none-bo td, .none-bo table {\n  border: none !important;\n}\n\n.none-bo th, .none-bo td {\n  text-align: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
