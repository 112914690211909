import { Routes, BrowserRouter, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import HomePage from "./components/HomePage";
import Master from "./components/Master";
import Products from "./components/Products";
import ProductCategory from "./components/ProductCategory";
import { createContext, useEffect, useState } from "react";
import { axiosClient } from "./services/common/axios-client";
import Cart from "./components/Cart/index";
import Detail from "./components/Detail";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, userActions } from "./redux/slide/user-slice";
import Checkout from "./components/Checkout";
import Information from "./components/User";
import UpdateInformation from "./components/User/Update";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageError from "./components/Page/404";
import { cartActions } from "./redux/slide/cart-slice";
import { getInformationUser } from "./services/common/api/api-private";
import Blog from "./components/Blog/index";
import DetailBlog from "./components/Blog/BlogDetail";
import Introduce from "./components/Page/Introduce";
import ShippingPolicy from "./components/Page/Policy/Shipping";
import TermOfUser from "./components/Page/Policy/TermOfUser";
import BuyingGuide from "./components/Page/Policy/BuyingGuide";
import PrivacyPolicy from "./components/Page/Policy/Privacy/index";
import Project from "./components/Page/Project";
import ResetPassword from "./components/LoginAndSignin/ResetPassword";
import Contact from "./components/Page/Contact";
import Search from "./components/Page/Search";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Login from "./components/LoginAndSignin/Login/index";
import SignIn from "./components/LoginAndSignin/SignIn/index";
import { getSeoGlobal } from "./services/common/api/api-public";
import Seo from "./components/Utils/seo";
import {
  clearTokens,
  getAccessToken,
} from "./components/Utils/tokenManagement";

export const UserContext = createContext(null);

function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const [defaultSeo, setDefaultSeo] = useState();
  const jwt = getAccessToken();
  useEffect(() => {
    getSeoGlobal().then((res) => {
      setDefaultSeo(res.data.attributes.defaultSeo);
    });
  }, []);

  useEffect(() => {
    if (jwt) {
      axiosClient
        .get("/users/me", {
          headers: {
            Authorization: "Bearer " + jwt,
          },
        })
        .then((res) => {
          dispatch(userActions.setUserInfo(res.data));
        })
        .catch((err) => {
          clearTokens();
          dispatch(userActions.setUserInfo({}));
        });
    }
  }, [jwt, dispatch]);

  useEffect(() => {
    if (jwt) {
      getInformationUser(jwt).then((res) => {
        dispatch(cartActions.setCartSize(res.orders.length));
      });
    }
  }, [jwt, dispatch]);

  if (!userInfo.username) {
    return (
      <>
        <HelmetProvider>
          <Seo defaultSeo={defaultSeo} />
          <Helmet>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
            />
          </Helmet>
          <UserContext.Provider value={userInfo}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Master />}>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/products" element={<Products />} />
                  <Route
                    path="/products-category/:name"
                    element={<ProductCategory />}
                  />
                  <Route path="/product-detail/:id" element={<Detail />} />
                  <Route path="/register" element={<SignIn />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />

                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:id" element={<DetailBlog />} />
                  <Route path="/introduce" element={<Introduce />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/shipping-policy" element={<ShippingPolicy />} />
                  <Route path="/terms-of-user" element={<TermOfUser />} />
                  <Route path="/buying-guide" element={<BuyingGuide />} />
                  <Route path="/project" element={<Project />} />
                  <Route path="/contact" element={<Contact />} />

                  <Route path="*" element={<PageError />} />
                  <Route path="/search" element={<Search />} />
                </Route>
              </Routes>
              <ToastContainer />
            </BrowserRouter>
          </UserContext.Provider>
        </HelmetProvider>
      </>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Seo defaultSeo={defaultSeo} />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0"
          />
        </Helmet>
        <UserContext.Provider value={userInfo}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Master />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/products" element={<Products />} />
                <Route
                  path="/products-category/:name"
                  element={<ProductCategory />}
                />
                <Route path="/product-detail/:id" element={<Detail />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/account/:id" element={<Information />} />
                <Route path="/account/update" element={<UpdateInformation />} />

                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<DetailBlog />} />
                <Route path="/introduce" element={<Introduce />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/shipping-policy" element={<ShippingPolicy />} />
                <Route path="/terms-of-user" element={<TermOfUser />} />
                <Route path="/buying-guide" element={<BuyingGuide />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/project" element={<Project />} />

                <Route path="*" element={<PageError />} />
                <Route path="/search" element={<Search />} />
              </Route>
            </Routes>
            <ToastContainer />
          </BrowserRouter>
        </UserContext.Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
