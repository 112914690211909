// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --border-color: #ebebeb;
    --input-background-color: transparent;
    --button-background-color: #3e890f;
    --button-background-color-hover: #39a45e;
    --button-text-color: white;
}

.email-in {
    padding: 10px 15px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 100%;
    background-color: var(--input-background-color);
}

.email-in:focus {
    outline: none;
}

.btn-login-l {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    transition: all 0.3s ease;
}

.btn-login-l:hover {
    background-color: var(--button-background-color-hover);
}

.login-rps {
    padding-right: 30px;
}

.custom-swal-button {
    background-color: var(--button-background-color) !important;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginAndSignin/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,qCAAqC;IACrC,kCAAkC;IAClC,wCAAwC;IACxC,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;IAClB,WAAW;IACX,+CAA+C;AACnD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,gDAAgD;IAChD,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2DAA2D;IAC3D,YAAY;AAChB","sourcesContent":[":root {\n    --border-color: #ebebeb;\n    --input-background-color: transparent;\n    --button-background-color: #3e890f;\n    --button-background-color-hover: #39a45e;\n    --button-text-color: white;\n}\n\n.email-in {\n    padding: 10px 15px;\n    border: 1px solid var(--border-color);\n    border-radius: 5px;\n    width: 100%;\n    background-color: var(--input-background-color);\n}\n\n.email-in:focus {\n    outline: none;\n}\n\n.btn-login-l {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: var(--button-background-color);\n    color: var(--button-text-color);\n    transition: all 0.3s ease;\n}\n\n.btn-login-l:hover {\n    background-color: var(--button-background-color-hover);\n}\n\n.login-rps {\n    padding-right: 30px;\n}\n\n.custom-swal-button {\n    background-color: var(--button-background-color) !important;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
