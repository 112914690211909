import { useState } from "react";
import partner from "../../assets/homePage/partner.webp";
import "./Partner.css";
import { createPartnerForm } from "../../services/common/api/api-public";
import Swal from "sweetalert2";
export default function Partner() {
  const [phone, setPhone] = useState("");
  const onHandleSendFormContact = () => {
    createPartnerForm(phone).then((res) => {
      let timerInterval;
      Swal.fire({
        text: "Gửi đơn đăng ký thành công",
        icon: "success",
        confirmButtonText: "Đồng ý (5)",
        customClass: {
          confirmButton: "custom-swal-button",
        },
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          const swalConfirmButton = Swal.getConfirmButton();
          let timeLeft = 5;

          timerInterval = setInterval(() => {
            timeLeft -= 0.1;
            swalConfirmButton.textContent = `Đồng ý (${Math.ceil(timeLeft)})`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    });
  };

  return (
    <>
      <div
        className="container-fl"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <div
          className="d-flex"
          style={{
            flexWrap: "wrap",
            backgroundColor: "rgb(198 239 213 / 55%)",
            borderRadius: "60px",
          }}
        >
          <div
            className="col-lg-6 col-12 d-flex align-items-center rps-in-bl"
            style={{ margin: "70px 0px 0px 0px" }}
          >
            <div style={{ width: "100%" }}>
              <div>
                <h2
                  className="rps-title1-h"
                  style={{ fontWeight: "bold", lineHeight: "normal" }}
                >
                  ĐĂNG KÝ TRỞ THÀNH <br />
                  <span style={{ color: "#3e890f" }}> ĐỐI TÁC </span>CỦA
                  CHÚNG TÔI
                </h2>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="input-partner"
                  placeholder="Hãy để lại SĐT, chúng tôi sẽ liên lạc lại"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <button
                  className="btn-partner"
                  onClick={onHandleSendFormContact}
                >
                  Đăng ký
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-12"
            style={{ margin: "70px 0px 0px 0px" }}
          >
            <img src={partner} alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
}
