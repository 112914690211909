import { Link, useNavigate, useParams } from "react-router-dom";
import "./ProductCategory.css";
import React, { useEffect, useState } from "react";
import { axiosClient } from "../../services/common/axios-client";
import {
  getCategories,
  getProductsByCategory,
} from "../../services/common/api/api-public";
import Swal from "sweetalert2";
export default function ProductCategory() {
  const params = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState("");
  const breadcrumb = params.name;
  const name = params.name.replace(/\s/g, "%20");
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  useEffect(() => {
    document.title = `${params.name} - Ngọc Oanh Foods`;
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await getProductsByCategory(name);
        setProducts(res);

        const cate = await getCategories();
        setCategories(cate.data);
        setLoading(false);
      } catch (e) {}
    };
    fetchData();
  }, [name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    let sortBy = "";
    let sortDirection = "";
    switch (value) {
      case "asc":
        sortDirection = "ASC";
        sortBy = "name";
        break;
      case "desc":
        sortDirection = "DESC";
        sortBy = "name";
        break;
      case "priceAsc":
        sortDirection = "ASC";
        sortBy = "sub_cate.price";
        break;
      case "priceDesc":
        sortDirection = "DESC";
        sortBy = "sub_cate.price";
        break;
      case "new":
        sortDirection = "ASC";
        sortBy = "createdAt";
        break;
      case "old":
        sortDirection = "ASC";
        sortBy = "createdAt";
        break;
      default:
        sortDirection = "ASC";
        sortBy = "updatedAt";
        break;
    }
    setLoading(true);
    axiosClient
      .get(
        `/products?[filters][category][name][$in]=${name}&sort=${sortBy}:${sortDirection}&populate=*`
      )
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectSearchPrice = (e) => {
    const { value } = e.target;
    let price1 = "";
    let operator = "";
    let price2 = "";
    let url = "";
    switch (value) {
      case "under100":
        price1 = 100000;
        operator = "$lt";
        url = `filters[sub_cate][price][${operator}]=${price1}`;
        break;
      case "from100To200":
        price1 = 100000;
        price2 = 200000;
        operator = "$between";
        url = `filters[sub_cate][price][${operator}]=${price1}&filters[sub_cate][price][${operator}]=${price2}`;
        break;
      case "from200To300":
        price1 = 200000;
        price2 = 300000;
        operator = "$between";
        url = `filters[sub_cate][price][${operator}]=${price1}&filters[sub_cate][price][${operator}]=${price2}`;
        break;
      case "from300To500":
        price1 = 300000;
        price2 = 500000;
        operator = "$between";
        url = `filters[sub_cate][price][${operator}]=${price1}&filters[sub_cate][price][${operator}]=${price2}`;
        break;
      case "from500To1M":
        price1 = 500000;
        price2 = 1000000;
        operator = "$between";
        url = `filters[sub_cate][price][${operator}]=${price1}&filters[sub_cate][price][${operator}]=${price2}`;
        break;
      case "more":
        price1 = 1000000;
        operator = "$gte";
        url = `filters[sub_cate][price][${operator}]=${price1}`;
        break;
      default:
        url = "";
        break;
    }
    setLoading(true);
    axiosClient
      .get(`/products?[filters][category][name][$in]=${name}&${url}&populate=*`)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const onHandleSearchName = () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_PUBLIC_URL_STRAPI}/products?filters[name][$contains]=${searchParams}&populate=*`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.data.length >= 1) {
          setProducts(res.data);
        } else {
          Swal.fire({
            title: "Không tìm thấy sản phẩm!",
            icon: "error",
            confirmButtonText: "Đồng ý",
            customClass: {
              confirmButton: "custom-swal-button",
            },
          });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  if (!categories || !products) return <div className="loader"></div>;

  return (
    <>
      <div className="container-fl">
        <div>
          <p className="breadcrum">
            Sản phẩm \ <span style={{ color: "#3e890f" }}>{breadcrumb}</span>
          </p>
        </div>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="col-3 rps-sb-p">
            <div>
              <div className="box-filter">
                <div style={{ padding: "10px 30px" }} className="title-filter">
                  <p>DANH MỤC</p>
                </div>
                <div>
                  <div
                    className="filter-content"
                    style={{ margin: "0px 30px" }}
                  >
                    <p
                      onClick={() => {
                        navigate("/products");
                      }}
                    >
                      TẤT CẢ SẢN PHẨM
                    </p>
                    {categories?.map((cate) => (
                      <p
                        key={cate.id}
                        onClick={() => {
                          navigate(
                            `/products-category/${cate.attributes.name}`
                          );
                        }}
                      >
                        {cate.attributes.name}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="box-filter">
                <div style={{ padding: "10px 30px" }} className="title-filter">
                  <p>TÌM SẢN PHẨM</p>
                </div>
                <div>
                  <div className="filter-content">
                    <div className="px-3 py-3 d-flex">
                      <input
                        type="text"
                        placeholder="Tìm kiếm sản phẩm"
                        className="sideBar-search"
                        onChange={(e) => setSearchParams(e.target.value)}
                      />
                      <button
                        className="sideBar-search-btn"
                        onClick={onHandleSearchName}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="filter-options "
                      style={{ justifyContent: "center" }}
                      onChange={handleSelectSearchPrice}
                    >
                      <div className="ms-3">
                        <label>
                          <input type="radio" name="price" value="under100" />{" "}
                          Giá dưới 100.000đ
                        </label>
                      </div>
                      <div className=" ms-3">
                        <label>
                          <input
                            type="radio"
                            name="price"
                            value="from100To200"
                          />{" "}
                          100.000đ - 200.000đ
                        </label>
                      </div>
                      <div className=" ms-3">
                        <label>
                          <input
                            type="radio"
                            name="price"
                            value="from200To300"
                          />{" "}
                          200.000đ - 300.000đ
                        </label>
                      </div>
                      <div className="ms-3">
                        <label>
                          <input
                            type="radio"
                            name="price"
                            value="from300To500"
                          />{" "}
                          300.000đ - 500.000đ
                        </label>
                      </div>
                      <div className=" ms-3">
                        <label>
                          <input
                            type="radio"
                            name="price"
                            value="from500To1M"
                          />{" "}
                          500.000đ - 1.000.000đ
                        </label>
                      </div>
                      <div className=" ms-3">
                        <label>
                          <input type="radio" name="price" value="more" /> Giá
                          trên 1.000.000đ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-filter">
                <div style={{ padding: "10px 30px" }} className="title-filter">
                  <p>KHUYỄN MÃI HOT</p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-12 container-list-p">
            <div
              className="d-flex pt-2 pb-3"
              style={{
                borderBottom: "1px solid #ebebeb",
                justifyContent: "end",
              }}
            >
              <span
                className="me-2"
                style={{ fontSize: "14px", alignContent: "center" }}
              >
                Sắp xếp
              </span>
              <select
                defaultValue="default"
                className="sort-filter"
                onChange={handleSelectChange}
              >
                <option value="default">Mặc định</option>
                <option value="asc">A - Z</option>
                <option value="desc">Z - A</option>
                <option value="priceAsc">Giá tăng dần</option>
                <option value="priceDesc">Giá giảm dần</option>
                <option value="new">Hàng mới nhất</option>
                <option value="old">Hàng cũ nhất</option>
              </select>
            </div>

            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="product-list">
                {products?.data?.map((p, index) => (
                  <ProductCard key={index} product={p} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="filter-popup-icon"
        onClick={() => {
          setIsOpenFilter(true);
        }}
      >
        <span className="material-symbols-outlined">filter_alt</span>
      </div>

      <div className={`filter-popup ${isOpenFilter ? "filter-popup-vis" : ""}`}>
        <div style={{ position: "relative" }}>
          <div style={{ overflowY: "scroll", maxHeight: "100vh" }}>
            <div
              className="close-filter-pop"
              onClick={() => {
                setIsOpenFilter(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>DANH MỤC</p>
              </div>
              <div>
                <div className="filter-content" style={{ margin: "0px 30px" }}>
                  <p
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    TẤT CẢ SẢN PHẨM
                  </p>
                  {categories?.map((cate) => (
                    <p
                      key={cate.id}
                      onClick={() => {
                        navigate(`/products-category/${cate.attributes.name}`);
                      }}
                    >
                      {cate.attributes.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>TÌM SẢN PHẨM</p>
              </div>
              <div>
                <div className="filter-content">
                  <div className="px-3 py-3 d-flex">
                    <input
                      type="text"
                      placeholder="Tìm kiếm sản phẩm"
                      className="sideBar-search"
                      onChange={(e) => setSearchParams(e.target.value)}
                    />
                    <button
                      className="sideBar-search-btn"
                      onClick={onHandleSearchName}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                      </svg>
                    </button>
                  </div>
                  <div
                    className="filter-options "
                    style={{ justifyContent: "center" }}
                    onChange={handleSelectSearchPrice}
                  >
                    <div className="ms-3">
                      <label>
                        <input type="radio" name="price" value="under100" /> Giá
                        dưới 100.000đ
                      </label>
                    </div>
                    <div className=" ms-3">
                      <label>
                        <input type="radio" name="price" value="from100To200" />{" "}
                        100.000đ - 200.000đ
                      </label>
                    </div>
                    <div className=" ms-3">
                      <label>
                        <input type="radio" name="price" value="from200To300" />{" "}
                        200.000đ - 300.000đ
                      </label>
                    </div>
                    <div className="ms-3">
                      <label>
                        <input type="radio" name="price" value="from300To500" />{" "}
                        300.000đ - 500.000đ
                      </label>
                    </div>
                    <div className=" ms-3">
                      <label>
                        <input type="radio" name="price" value="from500To1M" />{" "}
                        500.000đ - 1.000.000đ
                      </label>
                    </div>
                    <div className=" ms-3">
                      <label>
                        <input type="radio" name="price" value="more" /> Giá
                        trên 1.000.000đ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-filter">
              <div style={{ padding: "10px 30px" }} className="title-filter">
                <p>KHUYỄN MÃI HOT</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`filter-popup-layer ${
          isOpenFilter ? "filter-popup-layer-vis" : ""
        }`}
        onClick={() => {
          setIsOpenFilter(false);
        }}
      ></div>
    </>
  );
}

const ProductCard = React.memo(({ product }) => {
  const prices = product?.attributes.sub_cate.map(
    (subCategory) => subCategory.price
  );
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  return (
    <div className="col-lg-4 col-sm-6 col-6 container-card-p">
      <div
        className="product-card-p"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div>
          <Link to={`/product-detail/${product.id}`}>
            <img
              src={
                process.env.REACT_APP_URL +
                product?.attributes?.currentImage?.data.attributes.url
              }
              alt="..."
              width="100%"
              height="250px"
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
        </div>
        <div
          className="d-flex"
          style={{
            flex: "1",
            justifyContent: "center",
            paddingTop: "25px",
            paddingBottom: "15px",
            minHeight: "60px",
            textAlign: "center",
          }}
        >
          <Link to={`/product-detail/${product.id}`} className="title-card-p">
            {product?.attributes.name}
          </Link>
        </div>
        <div>
          <p className="price-p">
            {minPrice === maxPrice ? (
              new Intl.NumberFormat("vi-VN", {
                style: "currency",
                currency: "VND",
              }).format(minPrice)
            ) : (
              <>
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(minPrice)}{" "}
                -{" "}
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(maxPrice)}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
});
