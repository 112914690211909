// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary-color: #3e890f;
  --primary-hover-color: #4da012;
  --background-color: #ffffff;
  --text-color: #333333;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

.search-container {
  display: flex;
  align-items: center;
  width: 300px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 5px var(--shadow-color);
  background-color: var(--background-color);
}

.search-input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 16px;
  color: var(--text-color);
  background-color: var(--background-color);
}

.search-button {
  background-color: var(--primary-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button i {
  font-size: 16px;
}

.search-button:hover {
  background-color: var(--primary-hover-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Page/404.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,2BAA2B;EAC3B,qBAAqB;EACrB,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,uCAAuC;EACvC,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,aAAa;EACb,eAAe;EACf,wBAAwB;EACxB,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;EACtC,YAAY;EACZ,aAAa;EACb,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[":root {\n  --primary-color: #3e890f;\n  --primary-hover-color: #4da012;\n  --background-color: #ffffff;\n  --text-color: #333333;\n  --shadow-color: rgba(0, 0, 0, 0.1);\n}\n\n.search-container {\n  display: flex;\n  align-items: center;\n  width: 300px;\n  border-radius: 5px;\n  overflow: hidden;\n  box-shadow: 0 0 5px var(--shadow-color);\n  background-color: var(--background-color);\n}\n\n.search-input {\n  width: 100%;\n  padding: 10px;\n  border: none;\n  outline: none;\n  font-size: 16px;\n  color: var(--text-color);\n  background-color: var(--background-color);\n}\n\n.search-button {\n  background-color: var(--primary-color);\n  border: none;\n  padding: 10px;\n  cursor: pointer;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.search-button i {\n  font-size: 16px;\n}\n\n.search-button:hover {\n  background-color: var(--primary-hover-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
