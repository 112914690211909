// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-12 img {
    max-width: 100%; /* Ensures image width fits within the container */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Removes any extra space around the image */
    object-fit: contain; /* Scales the image to fit within the container without cropping */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Page/Introduce/introduce.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,kDAAkD;IACnE,YAAY,EAAE,2BAA2B;IACzC,cAAc,EAAE,6CAA6C;IAC7D,mBAAmB,EAAE,kEAAkE;EACzF","sourcesContent":[".col-12 img {\n    max-width: 100%; /* Ensures image width fits within the container */\n    height: auto; /* Maintains aspect ratio */\n    display: block; /* Removes any extra space around the image */\n    object-fit: contain; /* Scales the image to fit within the container without cropping */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
