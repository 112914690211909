import { useEffect, useState } from "react";
import { getShippingPolicy } from "../../../../services/common/api/api-public";
import { Link } from "react-router-dom";

export default function ShippingPolicy() {
  const [shipping, setShipping] = useState();
  useEffect(() => {
    document.title = "Chính sách vận chuyển - Ngọc Oanh Foods";
  });

  useEffect(() => {
    getShippingPolicy().then((res) => {
      setShipping(res.data);
    });
  }, []);

  if (!shipping) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl mt-5">
        <div className="col-12 mt-3">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \{" "}
          <span style={{ color: "#3e890f" }}>
            {shipping?.attributes.title}
          </span>
        </div>
        <div className="col-12 mt-2">
          <h4>{shipping?.attributes?.title}</h4>
        </div>
        <div
          className="mt-3"
          style={{
            maxWidth: "100%",
            color: "#323c3f",
            marginBottom: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: shipping?.attributes.content || "Chưa có dữ liệu",
          }}
        ></div>
      </div>
    </>
  );
}
