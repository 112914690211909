import axios from "axios";

export const axiosClient = axios.create({
  baseURL:
    process.env.REACT_APP_PUBLIC_URL_STRAPI || "http://localhost:1337/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer",
    "Accept-Language": "en",
  },
});
