export const generateUniquePaymentId = (existingIds) => {
  let idPayment;
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = String(today.getFullYear()).slice(-2);
  const dateStr = `${day}${month}${year}`;

  const randomLetter = String.fromCharCode(Math.floor(Math.random() * 26) + 65);
  const randomNumbers = (Math.floor(Math.random() * 90) + 10).toString();

  idPayment = `${dateStr}${randomLetter}${randomNumbers}`;

  while (existingIds.has(idPayment)) {
    idPayment = `${dateStr}${randomLetter}${(
      Math.floor(Math.random() * 90) + 10
    ).toString()}`;
  }

  return idPayment;
};
