import { axiosClient } from "../axios-client";

export const getInformationUser = async (jwt) => {
  const response = await axiosClient.get("/users/me?populate=*", {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  });
  return response.data;
};

export const updateInformationUser = async (userId, data, jwt) => {
  const response = await axiosClient.put(`/users/${userId}`, data, {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  });
  return response.data;
};

export const addToCart = async (product, jwt) => {
  const response = await axiosClient.post(`/orders`, product, {
    headers: {
      Authorization: "Bearer " + jwt,
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const checkProductOnCart = async (userId, productId, jwt) => {
  const response = await axiosClient.get(
    `/orders?filters[userId]=${userId}&filters[products]=${productId}&populate=*`,
    {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    }
  );
  return response.data;
};

export const updateProductOnCart = async (cartId, quantity, amount, jwt) => {
  const res = await axiosClient.put(
    `/orders/${cartId}`,
    JSON.stringify({ data: { quantity, amount } }),
    {
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
    }
  );
  return res.data;
};

export const deleteItemOnCart = async (id, jwt) => {
  const response = await axiosClient.delete(`/orders/${id}`, {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  });
  return response.data;
};

export const checkIdSubCate = async (userId, productId, idSubCate, jwt) => {
  const response = await axiosClient.get(
    `/orders?filters[userId][$eq]=${userId}&filters[products][$eq]=${productId}&filters[id_sub_category][$eq]=${idSubCate}`,
    {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    }
  );
  return response.data;
};

export const getCartItems = async (userId, jwt) => {
  const res = await axiosClient.get(
    `/orders?filters[userId][$eq]=${userId}&[populate][products][fields]=name&populate[products][populate]=sub_cate`,
    {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    }
  );
  return res.data;
};

export const getCartOfUser = async (userId, jwt) => {
  const responsive = await axiosClient.get(
    `/orders?filters[userId][$eq]=${userId}&populate[products][populate]=currentImage&populate[products][populate]=sub_cate`,
    {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    }
  );
  return responsive.data;
};

export const createBill = async (data, jwt) => {
  try {
    const response = await axiosClient.post(
      `/bills`,
      JSON.stringify({ data: data }),
      {
        headers: {
          Authorization: "Bearer " + jwt,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderHistories = async (userId, jwt) => {
  const response = await axiosClient.get(`/bills?filters[userId]=${userId}`, {
    headers: {
      Authorization: "Bearer " + jwt,
    },
  });
  return response.data;
};

export const updateSubCategoryQuantity = async (productId, data, token) => {
  try {
    const response = await axiosClient.put(
      `/products/${productId}`,
      { data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating subcategory quantity:", error);
    throw error;
  }
};
