import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slide/user-slice";
import { cartActions } from "../../redux/slide/cart-slice";
import {
  checkIdSubCate,
  deleteItemOnCart,
  getCartOfUser,
  updateProductOnCart,
} from "../../services/common/api/api-private";
import { getAccessToken } from "../Utils/tokenManagement";

export default function Cart() {
  const userInfo = useSelector(getUserInfo);
  const [cartItems, setCartItems] = useState();
  const jwt = getAccessToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `Giỏ hàng - Ngọc Oanh Foods`;
  });

  const getCartList = async () => {
    try {
      const res = await getCartOfUser(userInfo.id, jwt);
      setCartItems(res.data);
      const size = res.data.reduce((c, cart) => {
        return c + cart.attributes.quantity;
      }, 0);
      dispatch(cartActions.setCartSize(size));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCartList();
  }, [jwt, userInfo.id]);

  const onDeleteItem = async (productId) => {
    try {
      await deleteItemOnCart(productId, jwt);
      await getCartList();
    } catch (error) {
      console.log(error);
    }
  };

  if (!cartItems?.length || cartItems === null || cartItems === undefined)
    return (
      <div className="container-fl mt-5">
        <h1>Không có sản phẩm nào trong giỏ hàng</h1>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/products")}
        >
          Tiếp tục mua hàng
        </button>
      </div>
    );

  const totalPrice = cartItems.reduce((total, item) => {
    const amount =
      item?.attributes.products.data[0].attributes.sub_cate?.find(
        (sub) => sub.id === item?.attributes.id_sub_category
      )?.price || 0;

    return total + parseInt(amount) * item?.attributes.quantity;
  }, 0);

  const handleQuantityChange = async (cartItem, change) => {
    const newQuantity = cartItem.attributes.quantity + change;
    if (newQuantity < 1) return;

    try {
      const res = await checkIdSubCate(
        userInfo.id,
        cartItem.attributes.products.data[0].id,
        cartItem.attributes.id_sub_category,
        jwt
      );
      if (res.data.length > 0) {
        const existingCartItem = res.data[0];
        const updatedQuantity = existingCartItem.attributes.quantity + change;
        const updatedAmount =
          parseFloat(existingCartItem.attributes.amount) +
          parseFloat(
            cartItem.attributes.products.data[0].attributes.sub_cate.find(
              (sub) => sub.id === cartItem.attributes.id_sub_category
            ).price * change
          );

        await updateProductOnCart(
          existingCartItem.id,
          updatedQuantity,
          updatedAmount,
          jwt
        );

        const updatedCartItems = cartItems.map((item) =>
          item.id === existingCartItem.id
            ? {
                ...item,
                attributes: {
                  ...item.attributes,
                  quantity: updatedQuantity,
                  amount: updatedAmount,
                },
              }
            : item
        );
        setCartItems(updatedCartItems);

        const size = updatedCartItems.reduce(
          (c, cart) => c + cart.attributes.quantity,
          0
        );
        dispatch(cartActions.setCartSize(size));
      }
    } catch (error) {
      console.log("Failed to update cart item", error);
    }
  };

  return (
    <div className="container-fl">
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 row p-4">
        <div className="col-lg-8 col-md-8 col-xs-12">
          {cartItems.map((item) => (
            <div
              className="col-lg-12 col-md-12 col-xs-12 row mt-3 p-3 bg-light rounded shadow-sm"
              key={item.id}
              style={{ border: "solid 1px #ddd", borderRadius: "7px" }}
            >
              <div className="col-lg-2 col-md-12 col-xs-12 p-0">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    item.attributes.products.data[0].attributes.currentImage
                      .data.attributes.url
                  }
                  alt={"..."}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "7px",
                  }}
                />
              </div>
              <div className="col-lg-10 col-md-11 col-xs-10 p-3">
                <div className="col-12 row">
                  <div className="col-10">
                    <h5 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                      {item?.attributes.products?.data[0].attributes.name} -{" "}
                      {
                        item?.attributes.products?.data[0].attributes.sub_cate?.find(
                          (sub) => sub.id === item?.attributes.id_sub_category
                        )?.name
                      }
                    </h5>
                  </div>
                  <div className="col-2 text-end">
                    <div
                      style={{ cursor: "pointer", color: "black" }}
                      onClick={() => onDeleteItem(item.id)}
                    >
                      <svg
                        style={{ color: "#ff4d4f" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="col-12 row align-items-center mt-2">
                  <div className="col-7 d-flex align-items-center">
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => handleQuantityChange(item, -1)}
                      disabled={item.attributes.quantity === 1}
                      style={{ borderRadius: "50%" }}
                    >
                      -
                    </button>
                    <span className="mx-3 mt-2">
                      {item.attributes.quantity}
                    </span>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => handleQuantityChange(item, 1)}
                      style={{ borderRadius: "50%" }}
                    >
                      +
                    </button>
                  </div>
                  <div className="col-5 text-end">
                    <h6 style={{ color: "#333", fontWeight: "bold" }}>
                      {new Intl.NumberFormat("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      }).format(item.attributes.amount)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="col-lg-4 col-md-4 col-xs-12 col-sm-12 mt-2">
          <div style={{ position: "sticky", top: "100px" }}>
            <div className="p-4 bg-light rounded shadow-sm">
              <div className="row">
                <div className="col-7">
                  <h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>
                    Tổng tiền
                  </h4>
                </div>
                <div
                  className="col-5 text-end"
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "1.5em",
                  }}
                >
                  {new Intl.NumberFormat("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  }).format(totalPrice)}
                </div>
              </div>
              <div className="row text-center mt-4">
                <button
                  className="btn btn-success btn-lg w-100"
                  onClick={() => navigate("/checkout")}
                >
                  Tiến hành thanh toán
                </button>
                <button
                  className="btn btn-outline-primary btn-lg w-100 mt-3"
                  onClick={() => navigate("/products")}
                >
                  Tiếp tục mua sắm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
