import { useEffect, useState } from "react";
import { getPrivacyPolicy } from "../../../../services/common/api/api-public";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  const [privacy, setPrivacy] = useState();

  useEffect(() => {
    document.title = "Chính sách bảo mật - Ngọc Oanh Foods";
  });

  useEffect(() => {
    getPrivacyPolicy().then((res) => {
      setPrivacy(res.data);
    });
  }, []);
  if (!privacy) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl mt-5">
        <div className="col-12 mt-3">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \{" "}
          <span style={{ color: "#3e890f" }}>
            {privacy?.attributes.title}
          </span>
        </div>
        <div className="col-12 mt-2">
          <h4>{privacy?.attributes?.title}</h4>
        </div>
        <div
          className="mt-3"
          style={{
            maxWidth: "100%",
            color: "#323c3f",
            marginBottom: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: privacy?.attributes.content || "Chưa có dữ liệu",
          }}
        ></div>
      </div>
    </>
  );
}
