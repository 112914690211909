import { createSlice } from "@reduxjs/toolkit";

export const initialUserInfoState = {
  info: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserInfoState,
  reducers: {
    setUserInfo(state, action) {
      state.info = action.payload;
    },
    updateUserInfo(state, action) {
      state.info = { ...state.info, ...action.payload };
    },
  },
});

export const userActions = userSlice.actions;

export const { updateUserInfo } = userActions;

export const getUserInfo = (state) => state.user.info;

export default userSlice.reducer;
