import { useEffect, useState } from "react";
import { getProject } from "../../../services/common/api/api-public";
import { Link } from "react-router-dom";

export default function Project() {
  const [project, setProject] = useState();
  useEffect(() => {
    document.title = `Dự án sữa hạt yêu thương - Ngọc Oanh Foods`;
  });

  useEffect(() => {
    getProject().then((res) => {
      setProject(res.data);
    });
  }, []);

  if (!project) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl mt-5">
        <div className="col-12">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \{" "}
          <span style={{ color: "#3e890f" }}>
            {project?.attributes.title}
          </span>
        </div>
        <div className="col-12 mt-3">
          <h4>{project?.attributes.title}</h4>
        </div>
        <div className="col-12 mt-3">
          <img
            src={
              process.env.REACT_APP_URL +
              project?.attributes?.thumbnail?.data?.attributes?.url
            }
            alt={project?.attributes?.title}
          />
        </div>
        <div className="col-12 mt-3">
          <div
            dangerouslySetInnerHTML={{
              __html:
                project?.attributes?.content || "Chưa có dữ liệu chi tiết",
            }}
            style={{ maxWidth: "100%", overflow: "hidden" }}
          ></div>
        </div>
      </div>
    </>
  );
}
