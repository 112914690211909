
const setToken = (token) => {
  localStorage.setItem("refreshToken", token);
  sessionStorage.setItem("accessToken", token);
};

const getAccessToken = () => {
  return sessionStorage.getItem("accessToken");
};

const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

const clearTokens = () => {
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessToken");
};

export { setToken, getAccessToken, getRefreshToken, clearTokens };
