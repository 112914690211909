import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { register } from "../../../services/common/api/api-public";
import { setToken } from "../../Utils/tokenManagement";
export default function SignIn() {
  let timerInterval;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `Đăng ký tài khoản - Ngọc Oanh Foods`;
  });
  const onCreateAccount = (values) => {
    const { username, email, password } = values;

    register(username, email, password).then(
      (res) => {
        const user = res.user;
        setToken(JSON.stringify(user));
        setToken(res.jwt);
        Swal.fire({
          title: "Chúc mừng",
          text: "Đăng kí tài khoản thành công",
          icon: "success",
          confirmButtonText: "Đồng ý (5)",
          customClass: {
            confirmButton: "custom-swal-button",
          },
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            const swalConfirmButton = Swal.getConfirmButton();
            let timeLeft = 5;

            timerInterval = setInterval(() => {
              timeLeft -= 0.1;
              swalConfirmButton.textContent = `Đồng ý (${Math.ceil(timeLeft)})`;
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        navigate("/login");
      },
      (e) => {
        console.error("Registration failed", e);
      }
    );
  };

  return (
    <div className="container-fl">
      <p className="breadcrum">
        Sản phẩm \ <span style={{ color: "#3e890f" }}>Đăng ký</span>
      </p>

      <p style={{ color: "#333", fontSize: "30px", fontWeight: "400" }}>
        Đăng ký tài khoản
      </p>
      <p style={{ marginBottom: "7px" }}>
        Nếu bạn chưa có tài khoản, vui lòng đăng ký tại đây
      </p>

      <Formik
        initialValues={{ username: "", email: "", password: "" }}
        onSubmit={(values) => onCreateAccount(values)}
        validationSchema={Yup.object({
          username: Yup.string()
            .matches("^[a-zA-Z]", "Vui lòng nhập đúng định dạng tên bạn")
            .min(3, "Họ và tên ít nhất 3 kí tự")
            .required("Vui lòng không bỏ trống thông tin"),
          email: Yup.string()
            .min(3, "Email ít nhất 3 kí tự")
            .email()
            .required("Vui lòng không bỏ trống thông tin"),
          password: Yup.string()
            .min(6, "Mật khẩu ít nhất 6 kí tự")
            .required("Vui lòng không bỏ trống thông tin"),
        })}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Họ và tên
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="username"
                    className="email-in"
                    value={values.username}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="username"
                    component={"div"}
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Email:
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="email"
                    className="email-in"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="email"
                    component={"div"}
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="col-md-6 col-12">
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  Mật khẩu:
                </p>
                <div className="me-5 mb-4">
                  <Field
                    name="password"
                    className="email-in"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password"
                    component={"div"}
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <div className="col-6 mt-4">
                <button
                  className="btn-login-l"
                  type="submit"
                  disabled={
                    !(values.username && values.email && values.password)
                  }
                >
                  Đăng ký
                </button>
                <Link
                  to={"/login"}
                  style={{
                    textDecoration: "none",
                    color: "#333",
                    marginLeft: "20px",
                  }}
                >
                  Đăng nhập
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
