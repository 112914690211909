import { useEffect, useState } from "react";
import { getBuyingGuide } from "../../../../services/common/api/api-public";
import { Link } from "react-router-dom";

export default function BuyingGuide() {
  const [guide, setGuide] = useState();
  useEffect(() => {
    document.title = "Hướng dẫn mua hàng - Ngọc Oanh Foods";
  });

  useEffect(() => {
    getBuyingGuide().then((res) => {
      setGuide(res.data);
    });
  }, []);

  if (!guide) return <div className="loader"></div>;
  return (
    <>
      <div className="container-fl mt-5">
        <div className="col-12 mt-3">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \{" "}
          <span style={{ color: "#3e890f" }}>
            {guide?.attributes.title}
          </span>
        </div>
        <div className="col-12 mt-3">
          <h4>{guide?.attributes?.title}</h4>
        </div>
        <div
          className="mt-3"
          style={{
            maxWidth: "100%",
            color: "#323c3f",
            marginBottom: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html: guide?.attributes.content || "Chưa có dữ liệu",
          }}
        ></div>
      </div>
    </>
  );
}
