import "./Checkout.css";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slide/user-slice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { cartActions } from "../../redux/slide/cart-slice";
import {
  createBill,
  deleteItemOnCart,
  getCartItems,
  updateSubCategoryQuantity,
} from "../../services/common/api/api-private";
import { getSubCateById } from "../../services/common/api/api-public";
import { generateUniquePaymentId } from "../Utils/generateUniquePaymentId";
import { getAccessToken } from "../Utils/tokenManagement";

export default function Checkout() {
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const userInfo = useSelector(getUserInfo);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [address] = useState(userInfo.address || "");
  const [username] = useState(userInfo.username || "");
  const [phone] = useState(userInfo.phone || "");
  const [email] = useState(userInfo.email || "");
  const [message] = useState("");
  //eslint-disable-next-line
  const [temps, setTemps] = useState([]);
  const [idPayment, setIdPayment] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const jwt = getAccessToken();


  useEffect(() => {
    document.title = `Thanh toán - Ngọc Oanh Foods`;
  });

  useEffect(() => {
    if (userInfo) {
      getCartItems(userInfo.id, jwt).then((res) => {
        setCartItems(res.data);
        setTemps(res.data.map((item) => item.attributes.products.data[0]));
      });
    }
  }, [userInfo.id, jwt, userInfo, setTemps]);

  useEffect(() => {
    if (cartItems.length > 0) {
      const total = cartItems.reduce((totalPrice, order) => {
        return totalPrice + parseFloat(order.attributes.amount);
      }, 0);
      setTotalPrice(total);

      const newIdPayment = generateUniquePaymentId(new Set());
      setIdPayment(newIdPayment);
    }
  }, [cartItems]);

  const onHandlePayment = async (values) => {
    try {
      const orderItemList = cartItems.map((item) => ({
        quantity: item.attributes.quantity,
        totalPrice: item.attributes.amount,
        price: item.attributes.products.data[0].attributes.sub_cate?.find(
          (sub) => sub.id === item.attributes.id_sub_category
        )?.price,
        product: item.attributes.products.data[0].id,
        id_sub_cate: item.attributes.products.data[0].attributes.sub_cate?.find(
          (sub) => sub.id === item.attributes.id_sub_category
        )?.id,
      }));

      const payload = {
        email: values.email || email,
        username: values.username || username,
        phone: values.phone || phone,
        address: values.address || address,
        totalAmount: totalPrice,
        order_item_list: orderItemList,
        userId: userInfo.id,
        message: values.message || message,
        paymentId: idPayment,
        paymentMethod: paymentMethod,
      };

      await createBill(payload, jwt);

      for (const orderItem of orderItemList) {
        const productId = orderItem.product;
        const product = await getSubCateById(productId, jwt);
        const subCategories = product.data.attributes.sub_cate;
        const subCategoryIndex = subCategories.findIndex(
          (sub) => sub.id === orderItem.id_sub_cate
        );

        if (subCategoryIndex !== -1) {
          const updatedQuantity =
            subCategories[subCategoryIndex].quantity - orderItem.quantity;

          subCategories[subCategoryIndex].quantity = updatedQuantity;

          await updateSubCategoryQuantity(
            productId,
            {
              sub_cate: subCategories,
            },
            jwt
          );
        }
      }

      let timerInterval;
      Swal.fire({
        title: "Đơn hàng của bạn đã đặt thành công!",
        text: "Kiểm tra đơn hàng của bạn ở phần thông tin tài khoản",
        icon: "success",
        confirmButtonText: "Đồng ý (5)",
        customClass: {
          confirmButton: "custom-swal-button",
        },
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          const swalConfirmButton = Swal.getConfirmButton();
          let timeLeft = 5;

          timerInterval = setInterval(() => {
            timeLeft -= 0.1;
            swalConfirmButton.textContent = `Đồng ý (${Math.ceil(timeLeft)})`;
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then(() => {
        clearInterval(timerInterval);
      });

      for (const item of cartItems) {
        await deleteItemOnCart(item.id, jwt);
      }

      dispatch(cartActions.setCartSize(0));
      navigate("/");
    } catch (error) {
      console.error(
        "Error occurred:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (!cartItems.length || !cartItems) return <div className="loader"></div>;

  return (
    <div className="mt-5 container-fl col-12 row">
      <div className="col-12">
        <Button onClick={() => navigate(-1)} className="btn btn-line-success">
          Quay lại
        </Button>
      </div>

      {/* Cart items */}
      <div className="col-12 col-md-5 order-md-2 order-1">
        {cartItems.map((item) => (
          <div key={item.id} className="cart-item">
            <div className="row">
              <div className="col-8">
                <h6>
                  {item?.attributes.products.data[0].attributes.name} -{" "}
                  {
                    item?.attributes.products?.data[0].attributes.sub_cate?.find(
                      (sub) => sub.id === item?.attributes.id_sub_category
                    )?.name
                  }
                </h6>
              </div>
              <div className="col-4 text-right">
                <p>
                  {new Intl.NumberFormat("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  }).format(
                    item?.attributes.products.data[0].attributes.sub_cate?.find(
                      (sub) => sub.id === item?.attributes.id_sub_category
                    )?.price
                  )}{" "}
                  x {item?.attributes.quantity}
                </p>
              </div>
            </div>
          </div>
        ))}
        <hr />
        <div className="col-12 row">
          <strong className="col-9">Tổng tiền:</strong>
          <div className="col-3 total-price text-right">
            {new Intl.NumberFormat("vi-VN", {
              style: "currency",
              currency: "VND",
            }).format(totalPrice)}
          </div>
        </div>
      </div>

      {/* Shipping form  */}
      <div className="col-12 col-md-7 mt-3 order-md-1 order-2 p-3">
        <Formik
          initialValues={{
            username: userInfo.username || "",
            address: userInfo.default_address || "",
            phone: userInfo.phone || "",
            email: userInfo.email || "",
            message: "",
          }}
          validationSchema={Yup.object({
            username: Yup.string().required("Họ và tên là bắt buộc"),
            address: Yup.string()
              .min(6, "Địa chỉ không hợp lệ")
              .required("Địa chỉ là bắt buộc"),
            phone: Yup.string()
              .min(3, "Số điện thoại không hợp lệ")
              .required("Số điện thoại là bắt buộc"),
            email: Yup.string().email("Email không hợp lệ"),
          })}
          onSubmit={onHandlePayment}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="username" className="form-label">
                    Họ và tên
                  </label>
                  <Field
                    name="username"
                    type="text"
                    className={`form-control ${
                      errors.username && touched.username ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="phone" className="form-label">
                    Số điện thoại
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    className={`form-control ${
                      errors.phone && touched.phone ? "is-invalid" : ""
                    }`}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Địa chỉ email
                </label>
                <Field
                  name="email"
                  type="email"
                  className={`form-control ${
                    errors.email && touched.email ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Địa chỉ nhận hàng
                </label>
                <Field
                  name="address"
                  type="text"
                  className={`form-control ${
                    errors.address && touched.address ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Ghi chú (nếu có)
                </label>
                <Field name="message" as="textarea" className="form-control" />
              </div>

              <div className="mb-3">
                <label htmlFor="paymentMethod" className="form-label">
                  Phương thức thanh toán
                </label>
                <Field
                  as="select"
                  name="paymentMethod"
                  className="form-control"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value="COD">Thanh toán khi nhận hàng (COD)</option>
                  <option value="BANK_TRANSFER">Chuyển khoản ngân hàng</option>
                </Field>
              </div>

              {paymentMethod === "BANK_TRANSFER" && (
                <div className="alert alert-info mt-3">
                  <p>
                    Vui lòng chuyển khoản số tiền{" "}
                    <strong>
                      {new Intl.NumberFormat("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      }).format(totalPrice)}
                    </strong>{" "}
                    vào tài khoản sau:
                  </p>
                  <p>
                    <strong>Ngân hàng Vietcombank</strong> <br />
                    Chủ tài khoản: <b>Nguyễn Văn A</b> <br />
                    Số tài khoản: <b>1234567890</b> <br />
                    Chi nhánh: <b>Đà Nẵng</b> <br />
                    Nội dung: <b>Thanh toán cho đơn hàng: {idPayment} </b>
                  </p>
                </div>
              )}

              <Button
                type="submit"
                className="btn btn-primary w-100"
                variant="success"
                disabled={cartItems.length === 0}
              >
                Thanh toán
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
