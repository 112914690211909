import { axiosClient } from "../axios-client";

export const getCategories = async () => {
  const response = await axiosClient.get(`/categories?populate=*`);
  return response.data;
};

export const getSlidersSection = async () => {
  const response = await axiosClient.get(
    `/sliders?[filters][type]=section&populate=*`
  );
  return response.data;
};

export const getSlidersTestimonials = async () => {
  const res = await axiosClient.get(
    `/sliders?[filters][type]=testimonials&populate=*`
  );
  return res.data;
};

export const getProducts = async (start = 0, limit = 9) => {
  const response = await axiosClient.get(
    `/products?populate=*&pagination[start]=${start}&pagination[limit]=${limit}`
  );
  return response.data;
};

export const getProductsByCategory = async (name) => {
  const response = await axiosClient.get(
    "/products?[filters][category][name][$in]=" + name + "&populate=*"
  );
  return response.data;
};

export const findProductById = async (productId) => {
  const response = await axiosClient.get(`/products/${productId}?populate=*`);
  return response.data;
};

export const getAllProductByIdCate = async (id) => {
  const response = await axiosClient.get(
    `/products?[filters][category][id][$eq]=${id}&populate=*`
  );
  return response.data;
};

export const register = async (username, email, password) => {
  const res = await axiosClient.post(`/auth/local/register`, {
    username: username,
    email: email,
    password: password,
  });
  return res.data;
};

export const signIn = async (email, password) => {
  const res = await axiosClient.post(`/auth/local`, {
    identifier: email,
    password: password,
  });
  return res.data;
};

export const getSubCateById = async (productId, token) => {
  try {
    const response = await axiosClient.get(
      `/products/${productId}?populate[sub_cate][id]`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product with subcategories:", error);
    throw error;
  }
};

export const createFormContact = async (data) => {
  const response = await axiosClient.post(
    `/contact-forms`,
    JSON.stringify({ data: data })
  );
  return response.data;
};

export const getAllBlog = async () => {
  const response = await axiosClient.get(`/blogs?populate=*`);
  return response.data;
};

export const getBlogById = async (id) => {
  const response = await axiosClient.get(`/blogs/${id}?populate=*`);
  return response.data;
};

export const getGlobal = async () => {
  const res = await axiosClient.get(`/global?populate=*`);
  return res.data;
};

export const getIntroduce = async () => {
  const res = await axiosClient.get(`/introduce?populate=*`);
  return res.data;
};

export const getPrivacyPolicy = async () => {
  const res = await axiosClient.get(`/privacy-policy?populate=*`);
  return res.data;
};

export const getShippingPolicy = async () => {
  const res = await axiosClient.get(`/shipping-policy?populate=*`);
  return res.data;
};

export const getBuyingGuide = async () => {
  const res = await axiosClient.get(`/buying-guide?populate=*`);
  return res.data;
};

export const getTermOfUser = async () => {
  const res = await axiosClient.get(`/terms-of-use?populate=*`);
  return res.data;
};

export const getProject = async () => {
  const res = await axiosClient.get(`/project?populate=*`);
  return res.data;
};

export const getStory = async () => {
  const res = await axiosClient.get(`/story?populate=*`);
  return res.data;
};

export const getDescriptionFeedback = async () => {
  const res = await axiosClient.get(`/feedback`);
  return res.data;
};

export const getFeedbackPerson = async () => {
  const res = await axiosClient.get(
    `/global?populate[feedback][populate]=image`
  );
  return res.data;
};

export const getNutritionArticle = async () => {
  const res = await axiosClient.get(`/nutrition-articles?populate=*`);
  return res.data;
};

export const getStandard = async () => {
  const res = await axiosClient.get(`/quality-standard?populate=*`);
  return res.data;
};

export const getSection = async () => {
  const res = await axiosClient.get(`/section?populate=*`);
  return res.data;
};

export const getCorpInfo = async () => {
  const res = await axiosClient.get(`/corp-info?populate=*`);
  return res.data;
};

export const forgotPassword = async (email) => {
  const res = await axiosClient.post(`/auth/forgot-password`, {
    email: email,
  });
  return res.data;
};

export const resetPassword = async (code, newPassword, confirmNewPassword) => {
  const res = await axiosClient.post(`/auth/reset-password`, {
    password: newPassword,
    passwordConfirmation: confirmNewPassword,
    code: code,
  });
  return res.data;
};

export const createPartnerForm = async (phone) => {
  const res = await axiosClient.post(
    `/partner-forms`,
    JSON.stringify({ data: { phone } })
  );
  return res.data;
};

export const getSeoGlobal = async () => {
  try {
    const res = await axiosClient.get(
      `/global?populate[defaultSeo][populate]=metaImage`
    );
    return res.data;
  } catch (e) {
    console.error("Error fetching SEO Global:", e);
    throw e;
  }
};
