import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getBlogById,
  getCategories,
} from "../../../services/common/api/api-public";
import { formatDate } from "./../../Utils/formatDate";

export default function DetailBlog() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [blog, setBlog] = useState();
  const params = useParams();

  useEffect(() => {
    document.title = `${blog?.attributes?.title} - Ngọc Oanh Foods`;
  });

  useEffect(() => {
    const fetchData = async () => {
      const { id } = params;
      try {
        if (params && id) {
          getBlogById(id).then((res) => {
            setBlog(res.data);
          });
        }
        const categoriesRes = await getCategories();
        setCategories(categoriesRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [params]);

  return (
    <>
      <div>
        <div className="container-fl">
          <div>
            <p className="breadcrum">
              <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
                {" "}
                Trang chủ
              </Link>{" "}
              \{" "}
              <Link
                style={{ color: "black", textDecoration: "none" }}
                to={"/blog"}
              >
                Tin tức
              </Link>{" "}
              \{" "}
              <span style={{ color: "#3e890f" }}>{blog?.attributes.title}</span>
            </p>
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <div className="col-3 rps-sb-p">
              <div>
                <div className="box-filter">
                  <div
                    style={{ padding: "10px 30px" }}
                    className="title-filter"
                  >
                    <p>DANH MỤC</p>
                  </div>
                  <div>
                    <div
                      className="filter-content"
                      style={{ margin: "0px 30px" }}
                    >
                      <p
                        onClick={() => {
                          navigate("/products");
                        }}
                      >
                        TẤT CẢ SẢN PHẨM
                      </p>
                      {categories?.map((cate) => (
                        <p
                          key={cate.id}
                          onClick={() => {
                            navigate(
                              `/products-category/${cate.attributes.name}`
                            );
                          }}
                        >
                          {cate.attributes.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12 container-list-p">
              <div className="col-12">
                <h4>{blog?.attributes?.title}</h4>
                <span>{formatDate(blog?.attributes?.createdAt)}</span>
                <div>{blog?.attributes?.author}</div>
              </div>

              <div className="mt-2" style={{ textAlign: "center" }}>
                <img
                  src={
                    process.env.REACT_APP_URL +
                    blog?.attributes?.thumbnail.data.attributes.url
                  }
                  alt={blog?.attributes?.name}
                />
              </div>
              <div
                className="mt-3"
                style={{
                  maxWidth: "100%",
                  color: "#323c3f",
                  marginBottom: "20px",
                }}
                dangerouslySetInnerHTML={{
                  __html: blog?.attributes?.content || "Chưa có mô tả chi tiết",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
