// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --footer-bg-color: #3e890f;
    --footer-text-color: #f0f0f0;
    --footer-font-size: 15px;
    --footer-list-padding-left: 14px;
    --footer-list-margin-bottom: 10px;
}

.footer-container {
    background-color: var(--footer-bg-color);
    width: 100%;
}

.footer-container p, .footer-container li {
    margin-bottom: 0;
    color: var(--footer-text-color);
    font-size: var(--footer-font-size);
}

.footer-container ul {
    margin: 0;
    padding: 0px 0px 0px var(--footer-list-padding-left);
}

.footer-container li {
    margin-bottom: var(--footer-list-margin-bottom);
    cursor: pointer;
}

.fb-footer {
    margin-top: 0;
}

.policy-f {
    margin-top: 0;
}

@media (max-width: 992px) {
    .fb-footer {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .policy-f {
        margin-top: 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,wBAAwB;IACxB,gCAAgC;IAChC,iCAAiC;AACrC;;AAEA;IACI,wCAAwC;IACxC,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,SAAS;IACT,oDAAoD;AACxD;;AAEA;IACI,+CAA+C;IAC/C,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[":root {\n    --footer-bg-color: #3e890f;\n    --footer-text-color: #f0f0f0;\n    --footer-font-size: 15px;\n    --footer-list-padding-left: 14px;\n    --footer-list-margin-bottom: 10px;\n}\n\n.footer-container {\n    background-color: var(--footer-bg-color);\n    width: 100%;\n}\n\n.footer-container p, .footer-container li {\n    margin-bottom: 0;\n    color: var(--footer-text-color);\n    font-size: var(--footer-font-size);\n}\n\n.footer-container ul {\n    margin: 0;\n    padding: 0px 0px 0px var(--footer-list-padding-left);\n}\n\n.footer-container li {\n    margin-bottom: var(--footer-list-margin-bottom);\n    cursor: pointer;\n}\n\n.fb-footer {\n    margin-top: 0;\n}\n\n.policy-f {\n    margin-top: 0;\n}\n\n@media (max-width: 992px) {\n    .fb-footer {\n        margin-top: 30px;\n    }\n}\n\n@media (max-width: 768px) {\n    .policy-f {\n        margin-top: 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
