import { Link, useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

import "./HomePage.css";
import ct1 from "../../assets/homePage/category/cate_banh.jpg";
import { useEffect, useRef, useState } from "react";
import {
  getCategories,
  getDescriptionFeedback,
  getFeedbackPerson,
  getGlobal,
  getNutritionArticle,
  getSection,
  getSlidersSection,
  getSlidersTestimonials,
  getStandard,
  getStory,
} from "../../services/common/api/api-public";
import Partner from "../Partner";

export default function HomePage() {
  const categorySwiperRef = useRef();
  const navigate = useNavigate();
  const [certificationCurrent, setCertificationCurrent] = useState();
  const [flagCerStatus, setFlagCerStatus] = useState(false);
  const [openCer, setOpenCer] = useState(false);
  const [categories, setCategories] = useState();
  const [global, setGlobal] = useState();
  const [slidersSection, setSlidersSection] = useState();
  const [sliderTestimonials, setSliderTestimonials] = useState();
  const [imageIntroducing, setImageIntroducing] = useState();
  const [story, setStory] = useState();
  const [descriptionFb, setDescriptionFb] = useState();
  const [feedback, setFeedback] = useState();
  const [nutrition, setNutrition] = useState();
  const [qualityStandards, setQualityStandard] = useState();
  const [descriptionSection, setDescriptionSection] = useState();

  useEffect(() => {
    document.title = "Trang chủ - Ngọc Oanh Foods";
  });

  const openModalCer = (url) => {
    setCertificationCurrent(process.env.REACT_APP_URL + url);
    setFlagCerStatus(!flagCerStatus);
  };

  const closeModalCer = () => {
    setOpenCer(false);
  };

  useEffect(() => {
    if (certificationCurrent) {
      setOpenCer(true);
    }
  }, [flagCerStatus, certificationCurrent]);

  useEffect(() => {
    getCategories().then((res) => setCategories(res.data));
    getGlobal().then((res) => {
      setGlobal(res.data);
      setImageIntroducing(res.data.attributes.background_image);
    });
    getStory().then((res) => {
      setStory(res.data);
    });
    getDescriptionFeedback().then((res) => {
      setDescriptionFb(res.data);
    });
    getFeedbackPerson().then((res) => {
      setFeedback(res.data);
    });
    getNutritionArticle().then((res) => {
      setNutrition(res.data);
    });
    getStandard().then((res) => {
      setQualityStandard(res.data);
    });
    getSection().then((res) => {
      setDescriptionSection(res.data);
    });
  }, []);

  useEffect(() => {
    getSlidersSection().then((res) => {
      const value = [...res.data, ...res.data];
      setSlidersSection(value);
    });
    getSlidersTestimonials().then((res) => {
      const value = [...res.data, ...res.data];
      setSliderTestimonials(value);
    });
  }, []);

  if (!slidersSection || !categories) return <div className="loader"></div>;

  return (
    <div className="homePage-container">
      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoPlay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          speed={1100}
          navigation={true}
          modules={[Navigation, Autoplay]}
        >
          {global?.attributes.banner.data.map((item) => (
            <SwiperSlide key={item.id}>
              <div>
                <div className="d-flex justify-content-center">
                  <img
                    src={process.env.REACT_APP_URL + item?.attributes?.url}
                    alt="..."
                    className="img-cr1-h"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="banner-home mt-3">
        <div className="container-fl">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            autoPlay={{
              delay: 7500,
              disableOnInteraction: false,
            }}
            speed={1100}
            modules={[Navigation, Autoplay]}
            breakpoints={{
              380: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
          >
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-heart me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                </svg>
                <p>Đảm bảo chất lượng</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-truck me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>
                <p>Vận chuyển toàn quốc</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-arrow-repeat me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                  <path
                    fillRule="evenodd"
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                  />
                </svg>
                <p>Miễn phí trả đổi</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-heart me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                </svg>
                <p>Đảm bảo chất lượng</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-truck me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                </svg>
                <p>Vận chuyển toàn quốc</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex align-items-center justify-content-center banner-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  fill="currentColor"
                  className="bi bi-arrow-repeat me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                  <path
                    fillRule="evenodd"
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"
                  />
                </svg>
                <p>Miễn phí trả đổi</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="q-home d-flex container-fl" style={{ flexWrap: "wrap" }}>
        <div className="col-lg-7 col-12">
          <h2
            className="rps-title1-h"
            style={{ fontWeight: "bold", lineHeight: "normal" }}
          >
            TẠI SAO KHÁCH HÀNG <br />
            <span style={{ color: "#3e890f" }}> LỰA CHỌN</span> NGỌC OANH
            FOODS
          </h2>
        </div>
        <div className="col-lg-5 col-12">
          <p>{descriptionSection?.attributes.description}</p>
        </div>
      </div>

      <div className="container-fl box-container-h">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoPlay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          speed={1100}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            668: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
          }}
        >
          {slidersSection.map((s, index) => (
            <SwiperSlide key={index}>
              <div className="card-home-crs3">
                <div className="col-9">
                  <div className="d-flex justify-content-center mb-5">
                    <img
                      src={
                        process.env.REACT_APP_URL +
                        s?.attributes.image.data.attributes.url
                      }
                      alt="..."
                      height="80px"
                    />
                  </div>
                  <div>
                    <p
                      className="mb-4"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#3e890f",
                      }}
                    >
                      {s?.attributes.name}
                    </p>
                    <p className="content-crs3">{s?.attributes.content}</p>
                    <Link
                      to={"/introduce"}
                      className="mt-3 mb-2"
                      style={{ textAlign: "center", display: "block" }}
                    >
                      Tìm hiểu thêm &gt;
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="container-fl">
        <p
          style={{
            textAlign: "center",
            color: "#3e890f",
            fontWeight: "600",
          }}
          className="mb-4 rps-title2-h"
        >
          Các sản phẩm nổi bật
        </p>
      </div>

      <div style={{ width: "100%", overflow: "hidden" }}>
        <div className="container-fl box-container-h">
          <div className="list-cate-crs">
            <Swiper
              onSwiper={(swiper) => {
                categorySwiperRef.current = swiper;
              }}
              slidesPerView={2}
              spaceBetween={0}
              speed={1100}
              modules={[Navigation, Autoplay]}
              breakpoints={{
                380: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                420: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
            >
              <SwiperSlide>
                <div
                  className="category-h"
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <img src={ct1} alt="..." />
                  </div>
                  <div>
                    <p
                      className="mt-4 mb-3 mx-2"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      Tất cả sản phẩm
                    </p>
                  </div>
                </div>
              </SwiperSlide>

              {categories.map((cate) => (
                <SwiperSlide key={cate.id}>
                  <div
                    className="category-h"
                    onClick={() => {
                      navigate(`/products-category/${cate?.attributes.name}`);
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src={
                          process.env.REACT_APP_URL +
                          cate?.attributes.image.data.attributes.url
                        }
                        alt="..."
                      />
                    </div>
                    <div>
                      <p
                        className="mt-4 mb-3 mx-2"
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        {cate?.attributes?.name}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn-more-cate"
              onClick={() => {
                categorySwiperRef.current.slideNext();
              }}
            >
              Xem thêm
              <span className="material-symbols-outlined">chevron_right</span>
            </button>
          </div>
        </div>
      </div>

      <div className="q-home d-flex container-fl" style={{ flexWrap: "wrap" }}>
        <div className="col-lg-7 col-12">
          <h2
            className="rps-title1-h"
            style={{ fontWeight: "bold", lineHeight: "normal" }}
          >
            <span style={{ color: "#3e890f" }}>QUY TRÌNH </span>SẢN XUẤT
            <br />
            TẠI NGỌC OANH FOODS
          </h2>
        </div>
        <div className="col-lg-5 col-12">
          <p>{qualityStandards?.attributes.description}</p>
        </div>
      </div>

      <div className="container-fl box-container-h">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoPlay={{
            delay: 7500,
            disableOnInteraction: false,
          }}
          speed={1100}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            530: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
        >
          {sliderTestimonials?.map((s, index) => (
            <SwiperSlide key={index}>
              <div className="card-home-crs3">
                <div className="col-9">
                  <div className="d-flex justify-content-center mb-5">
                    <img
                      src={
                        process.env.REACT_APP_URL +
                        s?.attributes.image.data.attributes.url
                      }
                      alt="..."
                      height="80px"
                    />
                  </div>
                  <div>
                    <p
                      className="mb-4"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#3e890f",
                      }}
                    >
                      {s?.attributes.name}
                    </p>
                    <p className="content-crs3">{s?.attributes.content}</p>
                    <Link
                      to={"/introduce"}
                      className="mt-3 mb-2"
                      style={{ textAlign: "center", display: "block" }}
                    >
                      Tìm hiểu thêm &gt;
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="container-fl mb-5">
        <p
          className="rps-title2-h"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "600",
            color: "#3e890f",
          }}
        >
          Giới thiệu dây chuyền sản xuất
        </p>
      </div>
      {imageIntroducing && (
        <div className="box-container-h">
          <img
            src={
              process.env.REACT_APP_URL + imageIntroducing.data.attributes.url
            }
            alt={imageIntroducing.data.attributes.title || "Image"}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      )}

      {/* chung chi */}
      <div className="container-fl mb-5 cer-container">
        <p
          className="rps-title2-h mb-5"
          style={{
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "600",
            color: "#3e890f",
          }}
        >
          Các chứng chỉ đạt được
        </p>
        <Swiper
          slidesPerView={2}
          spaceBetween={0}
          speed={1100}
          navigation={true}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            380: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            420: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
        >
          {global?.attributes.certification.data.map((item, index) => (
            <SwiperSlide key={item.id} virtualIndex={index}>
              <div
                className="cer-card"
                onClick={() => {
                  openModalCer(item.attributes.url);
                }}
              >
                <img
                  src={process.env.REACT_APP_URL + item.attributes.url}
                  alt="..."
                  style={{ width: "100%" }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div>
        <div>
          <h2
            className="rps-title1-h"
            style={{
              fontWeight: "bold",
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            CÂU CHUYỆN{" "}
            <span style={{ color: "#3e890f" }}>NGỌC OANH FOODS</span>
          </h2>
        </div>
      </div>
      <div className="d-flex" style={{ flexWrap: "wrap" }}>
        <div className="col-lg-6 col-12">
          <img
            src={
              process.env.REACT_APP_URL +
              story?.attributes?.image?.data[0].attributes.url
            }
            alt="..."
            width="100%"
          />
        </div>
        <div className="col-lg-6 col-12" style={{ textAlign: "center" }}>
          {story?.attributes.description
            ? story?.attributes.description
            : "Chưa có mô tả chi tiết"}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#FFFCF3",
          padding: "70px 0px 100px 0px",
          marginBottom: "70px",
        }}
      >
        <div className="container-fl d-flex" style={{ flexWrap: "wrap" }}>
          <div className="col-lg-4 col-12" style={{ paddingTop: "30px" }}>
            <h2
              className="rps-title1-h"
              style={{ fontWeight: "bold", lineHeight: "normal" }}
            >
              <span style={{ color: "#3e890f" }}>CẢM ƠN </span>
              SỰ TIN TƯỞNG CỦA KHÁCH HÀNG
            </h2>
            <p className="mt-4">{descriptionFb?.attributes.description}</p>
          </div>

          {feedback?.attributes.feedback.map((fb, index) => (
            <div
              className="col-lg-4 col-12"
              style={{ paddingTop: "30px" }}
              key={index}
            >
              <div className="feedback-card mx-4 p-4">
                <div style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <img
                    src={
                      process.env.REACT_APP_URL + fb.image.data.attributes.url
                    }
                    alt={fb.name || "Feedback image"}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="mt-3">
                  <p>{fb.name}</p>
                  <p>{fb.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fl blog">
        <div>
          <h2
            className="rps-title1-h"
            style={{ fontWeight: "bold", lineHeight: "normal" }}
          >
            BÀI VIẾT CHUYÊN ĐỀ <br />
            <span style={{ color: "#3e890f" }}>
              DINH DƯỠNG - GIẢM CÂN{" "}
            </span>
          </h2>
        </div>

        <div className="d-flex pt-3" style={{ flexWrap: "wrap" }}>
          {nutrition?.map((nutr, index) => (
            <div
              className="col-lg-6 col-12 d-flex post-bl pe-5"
              style={{ flexWrap: "wrap" }}
              key={index}
            >
              <div className="col-3">
                <img
                  src={
                    process.env.REACT_APP_URL +
                    nutr?.attributes.image.data.attributes.url
                  }
                  alt={nutr?.attributes.title}
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/blog");
                  }}
                />
              </div>
              <div className="col-9">
                <Link to={"/blog"} className="title-blog">
                  {nutr?.attributes.title}
                </Link>
                <p>{nutr?.attributes.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {openCer ? (
        <div className="modal-certification">
          <div
            style={{
              position: "fixed",
              top: "0",
              right: "0",
              padding: "10px 10px",
              cursor: "pointer",
            }}
            onClick={closeModalCer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
          <img src={certificationCurrent} alt="..." className="img-cer" />
        </div>
      ) : (
        <></>
      )}
      <Partner />
    </div>
  );
}
