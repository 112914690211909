import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { useDispatch } from "react-redux";
import { userActions } from "../../../redux/slide/user-slice";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import {
  forgotPassword,
  signIn,
} from "../../../services/common/api/api-public";
import {
  getCartOfUser,
  getInformationUser,
} from "../../../services/common/api/api-private";
import { cartActions } from "../../../redux/slide/cart-slice";
import { getRefreshToken, setToken } from "../../Utils/tokenManagement";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resetEmail, setResetEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let timerInterval;
  useEffect(() => {
    document.title = `Đăng nhập - Ngọc Oanh Foods`;
  });

  const onSignIn = (values) => {
    const { email, password } = values;

    signIn(email, password)
      .then((res) => {
        if (res.jwt) {
          setToken(res.jwt);
          getRefreshToken();
          getInformationUser(res.jwt)
            .then((user) => {
              dispatch(userActions.setUserInfo(user));

              return getCartOfUser(user.id, res.jwt);
            })
            .then((cart) => {
              dispatch(cartActions.setCartSize(cart.data.length));
              Swal.fire({
                text: "Đăng nhâp thành công",
                icon: "success",
                confirmButtonText: "Đồng ý (5)",
                customClass: {
                  confirmButton: "custom-swal-button",
                },
                timer: 5000,
                timerProgressBar: true,
                didOpen: () => {
                  const swalConfirmButton = Swal.getConfirmButton();
                  let timeLeft = 5;

                  timerInterval = setInterval(() => {
                    timeLeft -= 0.1;
                    swalConfirmButton.textContent = `Đồng ý (${Math.ceil(
                      timeLeft
                    )})`;
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });

              navigate("/");
            })
            .catch((err) => {
              console.error("Error fetching user or cart information:", err);
            });
        }
      })
      .catch((err) => {
        toast.error("Tên tài khoản hoặc mật khẩu không chính xác!");
      });
  };

  const sendMail = async (email) => {
    setIsButtonDisabled(true);
    await forgotPassword(email)
      .then((res) => {
        Swal.fire({
          title: "Thông báo",
          text: "Đã gửi email xác nhận đặt lại mật khẩu, vui lòng kiểm tra email để đặt lại mật khẩu nhé!",
          icon: "success",
          confirmButtonText: "Đồng ý",
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Lỗi",
          text: "Có lỗi xảy ra khi gửi email. Vui lòng thử lại.",
          icon: "error",
          confirmButtonText: "Đồng ý",
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className="container-fl">
      <div>
        <p className="breadcrum">
          <Link style={{ color: "black", textDecoration: "none" }} to={"/"}>
            Trang chủ
          </Link>{" "}
          \ <span style={{ color: "#3e890f" }}>Đăng nhập</span>
        </p>
        <p style={{ color: "#333", fontSize: "30px", fontWeight: "400" }}>
          Đăng nhập tài khoản
        </p>
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="col-md-5 col-12 pe-4 mb-4">
            <p style={{ marginBottom: "7px" }}>
              Nếu bạn đã có tài khoản, đăng nhập tại đây
            </p>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => onSignIn(values)}
              validationSchema={Yup.object({
                email: Yup.string().required(
                  "Vui lòng không bỏ trống thông tin"
                ),
                password: Yup.string().required(
                  "Vui lòng không bỏ trống thông tin"
                ),
              })}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Email:
                  </p>
                  <div>
                    <Field
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      placeholder="Email"
                      className="email-in mb-3"
                    />
                    <ErrorMessage
                      name="email"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      marginBottom: "10px",
                    }}
                  >
                    Mật khẩu:
                  </p>

                  <div>
                    <Field
                      name="password"
                      onChange={handleChange}
                      value={values.password}
                      placeholder="Mật khẩu"
                      className="email-in mb-3"
                      type="password"
                    />
                    <ErrorMessage
                      name="password"
                      component={"div"}
                      style={{ color: "red" }}
                    />
                  </div>
                  <div>
                    <button className="btn-login-l" type="submit">
                      Đăng nhập
                    </button>
                    <Link
                      to={"/register"}
                      style={{
                        textDecoration: "none",
                        color: "#333",
                        marginLeft: "20px",
                      }}
                    >
                      Đăng ký
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-md-5 col-12 mb-4">
            <p style={{ marginBottom: "7px" }}>
              Bạn quên mật khẩu? Nhập địa chỉ email để lấy lại mật khẩu qua
              email.
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Email:
            </p>
            <div>
              <input
                placeholder="Email"
                className="email-in mb-3"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </div>
            <div>
              <button
                className="btn-login-l"
                onClick={() => sendMail(resetEmail)}
                disabled={isButtonDisabled}
              >
                Lấy lại mật khẩu
              </button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
