import { useSelector } from "react-redux";
import { getUserInfo } from "../../redux/slide/user-slice";
import "./user.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../Utils/formatDate";
import { getOrderHistories } from "../../services/common/api/api-private";
import { getAccessToken } from "../Utils/tokenManagement";

export default function Information() {
  const userInfo = useSelector(getUserInfo);
  const [orderHistories, setOrderHistories] = useState();
  const navigate = useNavigate();
  const jwt = getAccessToken();

  useEffect(() => {
    document.title = "Thông tin cá nhân";
  }, []);

  useEffect(() => {}, [userInfo, jwt]);

  useEffect(() => {
    getOrderHistories(userInfo.id, jwt).then((res) => {
      setOrderHistories(res.data);
    });
  }, [jwt, userInfo]);

  return (
    <>
      <div className="container mt-4">
        <div className="account-info col-12 row">
          <div className="col-lg-8 col-12">
            <h3>THÔNG TIN TÀI KHOẢN</h3>
            <p>
              Xin chào, <span className="username">{userInfo.username}</span>
            </p>
            <table>
              <thead
                style={{
                  backgroundColor: "#f9f9f9",
                }}
              >
                <tr>
                  <th>Đơn hàng</th>
                  <th>Ngày</th>
                  <th>Địa chỉ</th>
                  <th>Giá trị đơn hàng</th>
                  <th>Trạng thái thanh toán</th>
                  <th>Trạng thái giao hàng</th>
                </tr>
              </thead>
              <tbody>
                {orderHistories?.map((o) => (
                  <tr key={o.id}>
                    <td>{o.attributes.paymentId}</td>
                    <td>{formatDate(o.attributes.createdAt)}</td>
                    <td
                      style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {o.attributes.address}
                    </td>
                    <td>
                      {new Intl.NumberFormat("vi-VN", {
                        style: "currency",
                        currency: "VND",
                      }).format(o.attributes.totalAmount)}
                    </td>
                    <td>
                      {o.attributes.paymentStatus ? (
                        <p>Đã thanh toán</p>
                      ) : (
                        <p>Chưa thanh toán</p>
                      )}
                    </td>
                    <td>{o.attributes.deliveryStatus}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className="col-lg-4 col-12"
            style={{ backgroundColor: "#f9f9f9", borderRadius: "5px" }}
          >
            <div className="account-details col-12 mt-3 mb-3">
              <div className="col-12">
                <h4>TÀI KHOẢN CỦA TÔI</h4>
              </div>
              <div className="col-12">
                <table className="none-bo">
                  <tbody>
                    <tr>
                      <td>Tên tài khoản:</td>
                      <td>
                        <strong>{userInfo.username}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Địa chỉ:</td>
                      <td>
                        <strong>{userInfo.default_address}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Số điện thoại:</td>
                      <td>
                        <strong>{userInfo.phone}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Công ty:</td>
                      <td>
                        <strong>{userInfo.corp}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Thành phố:</td>
                      <td>
                        <strong>{userInfo.city}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Zip code:</td>
                      <td>
                        <strong>{userInfo.zip_code}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                className="button-upd"
                onClick={() => navigate("/account/update")}
              >
                Cập nhật thông tin
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
